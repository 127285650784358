import { yupResolver } from "@hookform/resolvers/yup";
import { CheckBox, CheckBoxOutlineBlank, ExpandMore } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary, Alert, AlertTitle, Autocomplete, Breadcrumbs, Button, Checkbox, CircularProgress, FormControl, Link, Paper, RadioGroup, TextField, Typography } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { FieldValues, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { useSnackbar } from "../../../core/contexts/SnackbarContext";
import { useLetras } from "../../../core/hooks/useLetras";
import { TipoPedido } from "../../../pedidos/enum/TipoPedido";
import { useCategorias } from "../../hooks/useCategorias";
import { useFetchCategorias } from "../../hooks/useFetchCategorias";
import { categoriaSchema } from "../../schema/categoriaSchema";
import { ButtonsContainer, ContentContainer, FormContainer, PageContainer, ResumoPaper } from "./styles";
import { IProduto } from "../../interfaces/IProduto";
import { useAutenticacaoContext } from "../../../core/contexts/AutenticacaoContext";
import { useMarketplaces } from "../../../marketplace/hooks/useMarketplaces";
import { useFetchMarketplaces } from "../../../marketplace/hooks/useFetchMarketplaces";
import { TipoMarketplace, TipoMarketplaceDescricao, TipoMarketplaceTitulo } from "../../../marketplace/enum/TipoMarketplace";
import { IMarketplaceCategoria } from "../../interfaces/IMarketplaceCategoria";
import { PeriodoExibicaoCategoria } from "../../components/PeriodoExibicaoCategoria";
import { IPeriodoExibicaoCategoria } from "../../interfaces/IPeriodoExibicaoCategoria";
import { DiaSemana, DiaSemanaOrdem } from "../../../core/enum/DiaSemana";
import { RadioCard } from "../../../core/components/RadioCard";
import { ModeloCategoria, ModeloCategoriaTitulo } from "../../enum/ModeloCategoria";

export const CadastrarEditarCategoria = () => {
    const { uuid } = useParams();
    const navigate = useNavigate();
    const [contextosAbertos, setContextosAbertos] = useState(true);
    const [marketplacesAbertos, setMarketplacesAbertos] = useState(true);
    const [periodosExibicaoAberto, setPeriodosExibicaoAberto] = useState(true);
    const [modeloCategoriaAberto, setmodeloCategoriaAberto] = useState(true);
    const [contextos, setContextos] = useState<TipoPedido[]>([]);
    const [marketplacesSelecionados, setMarketplacesSelecionados] = useState<IMarketplaceCategoria[]>([]);
    const [produtos, setProdutos] = useState<IProduto[]>([]);
    const [modelo, setModelo] = useState<ModeloCategoria>(ModeloCategoria.default);
    const { marketplaces, loading: loadingMarketplaces } = useFetchMarketplaces();
    const [periodosExibicao, setPeriodosExibicao] = useState<IPeriodoExibicaoCategoria[]>([]);

    const { salvar, loading } = useCategorias();

    const snackbar = useSnackbar();
    const { titulo } = useLetras();
    const { setValue, setError, register, handleSubmit, watch, formState: { errors } } = useForm({
        resolver: yupResolver(categoriaSchema),
        mode: "onChange",
    });

    const { categorias } = useFetchCategorias();

    const ultimaPosicao = useMemo(() => {
        let maiorPosicao = 1;

        if (!categorias?.length) {
            return maiorPosicao;
        }

        categorias.map(categoria => {
            if ((categoria.posicao ?? 1) > maiorPosicao) {
                maiorPosicao = categoria.posicao ?? 1
            }
        })

        return maiorPosicao + 1;
    }, [categorias])


    useEffect(() => {
        if (!uuid) {
            setValue("posicao", ultimaPosicao)
        }
    }, [ultimaPosicao, uuid])

    const contextosPadrao = [
        TipoPedido.delivery,
        TipoPedido.mesa,
        TipoPedido.retirada,
        TipoPedido.simples,
    ];

    useEffect(() => {
        if (!uuid) {
            setContextos(contextosPadrao);
        }
    }, [uuid])

    const categoria = useMemo(() => {
        if (uuid && categorias?.length) {
            const dados = categorias.find(categoria => categoria.uuid === uuid);
            return dados;
        }
        return undefined;
    }, [uuid, categorias])

    const adicionarPeriodoExibicao = (periodo: IPeriodoExibicaoCategoria) => {
        setPeriodosExibicao((state) => {
            const newState = [...state, periodo];
            newState.sort((a, b) => {
                return DiaSemanaOrdem[a.diaSemana] - DiaSemanaOrdem[b.diaSemana];
            });
            return newState;
        });
    };

    const excluirPeriodoExibicao = (diaSemana: DiaSemana) => {
        const periodoIndex = periodosExibicao?.findIndex(periodo => periodo.diaSemana == diaSemana);

        if (periodoIndex == -1) {
            return snackbar("Período não encontrado", { severity: "error" });
        }

        const novosPeriodosExibicao = [...periodosExibicao];
        novosPeriodosExibicao.splice(periodoIndex, 1);

        setPeriodosExibicao(novosPeriodosExibicao);
    };


    useEffect(() => {
        if (categoria) {
            setValue("descricao", categoria.descricao);
            setValue("posicao", categoria.posicao);
            setPeriodosExibicao(categoria?.periodosExibicao ?? []);
            setContextos(categoria.contextos ?? contextosPadrao);
            setProdutos(categoria.produtos ?? []);
            setModelo(categoria?.modelo ?? ModeloCategoria.default);

            if (categoria.marketplaces) {
                const marketplacesCategoria: IMarketplaceCategoria[] = [];

                categoria.marketplaces?.map(marketplaceCategoria => {
                    const marketplaceEncontrado = marketplaces?.find(marketplace => {
                        if ((marketplace.tipo == marketplaceCategoria.tipo) && marketplace.tipo == TipoMarketplace.PDV) {
                            return marketplace;
                        }

                        if (marketplace.uuid == marketplaceCategoria.uuid) {
                            return marketplace;
                        }
                    })

                    if (marketplaceEncontrado) marketplacesCategoria.push(marketplaceEncontrado);
                })

                setMarketplacesSelecionados(marketplacesCategoria);
            }
        }
    }, [categoria])

    const handleContextosChange = (evento: React.SyntheticEvent<Element, Event>, contextos: TipoPedido[]) => {
        setContextos(contextos);
    }

    const handleMarketplacesChange = (evento: React.SyntheticEvent<Element, Event>, marketplaces: IMarketplaceCategoria[]) => {
        const marketplaceExclusivo = marketplaces?.find(marketplace => marketplacesExclusivos.includes(marketplace.tipo));
        const uniqueMarketplaces = marketplaces.reduce((acc, current) => {
            if (!acc.some(marketplace => marketplace.uuid === current.uuid)) {
                acc.push(current);
            }
            return acc;
        }, [] as IMarketplaceCategoria[]);
        const pdvFilteredMarketplaces = uniqueMarketplaces.reduce((acc, current) => {
            if (current.tipo === TipoMarketplace.PDV) {
                if (!acc.some(marketplace => marketplace.tipo === TipoMarketplace.PDV)) {
                    acc.push(current);
                }
            } else {
                acc.push(current);
            }
            return acc;
        }, [] as IMarketplaceCategoria[]);
        setMarketplacesSelecionados(marketplaceExclusivo ? [marketplaceExclusivo] : pdvFilteredMarketplaces);
    };
    

    const handleSubmitForm = async ({ descricao, posicao }: FieldValues) => {
        const posicaoOcupada = categorias.some(categoriaProcurada => categoriaProcurada.posicao === posicao);

        if (posicaoOcupada && (categoria?.posicao !== posicao)) {
            setError('posicao', {
                type: 'manual',
                message: 'Essa posição já está ocupada por outra categoria',
            });
            return;
        }

        const resultado = await salvar({
            marketplaces: marketplacesSelecionados.map(marketplace => ({
                tipo: marketplace.tipo,
                uuid: marketplace.tipo != TipoMarketplace.PDV ? marketplace.uuid : undefined
            })),
            periodosExibicao: 
                marketplacesSelecionados?.some(marketplace => marketplacesOcultarPeriodoExibicao.includes(marketplace.tipo))
                ? []
                : periodosExibicao,
            descricao, 
            contextos:
                marketplacesSelecionados?.some(marketplace => marketplacesOcultarContextos.includes(marketplace.tipo))
                ? []
                : contextos,
            produtos,
            posicao,
            modelo,
            uuid
        })

        if (!resultado) {
            return snackbar("Ocorreu um erro ao salvar a categoria", { severity: "error" });
        }

        snackbar("Categoria salva com sucesso", { severity: "success" });
        navigate("/cardapio")
    }

    const { verificarAcessoFuncionalidade, perfil } = useAutenticacaoContext();
    useEffect(() => {
        if (perfil && !verificarAcessoFuncionalidade("Gestão de categorias")) {
            navigate("/nao-autorizado")
        }
    }, [perfil])

    const marketplacesOcultarPeriodoExibicao = [
        TipoMarketplace.IFOOD
    ];

    const marketplacesOcultarContextos = [
        TipoMarketplace.IFOOD
    ];

    const marketplacesExclusivos = [
        TipoMarketplace.IFOOD
    ];

    return (
        <PageContainer>
            <form onSubmit={handleSubmit(handleSubmitForm)}>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link color="primary" href="/cardapio">
                        Cardápio
                    </Link>
                    <Typography color="text.primary">{uuid ? "Editar" : "Cadastrar"} categoria</Typography>
                </Breadcrumbs>
                <Typography variant="h5" sx={{ marginTop: "2rem" }}>
                    {uuid ? "Editar" : "Cadastrar"} categoria
                </Typography>
                <ContentContainer>
                    <FormContainer>
                        <Accordion
                            expanded={true}
                            sx={{
                                "&:before": {
                                    display: "none",
                                },
                                "&.MuiAccordion-root::before": {
                                    display: "none",
                                },
                                borderRadius: "16px",
                            }}
                        >
                            <AccordionSummary>
                                <Typography variant="h6">Nome e posição</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <TextField
                                    label="Nome"
                                    style={{ width: "100%", margin: "0.7rem 0" }}
                                    {...register("descricao")}
                                    error={!!errors.descricao}
                                    helperText={<>{errors.descricao?.message}</>}
                                    InputLabelProps={{ shrink: true }}
                                />
                                <TextField
                                    label="Posição"
                                    style={{ width: "100%", margin: "0.7rem 0" }}
                                    {...register("posicao")}
                                    error={!!errors.posicao}
                                    helperText={<>{errors.posicao?.message}</>}
                                    InputLabelProps={{ shrink: true }}
                                />
                            </AccordionDetails>
                        </Accordion>
                        <Accordion
                            expanded={marketplacesAbertos}
                            sx={{
                                "&:before": {
                                    display: "none",
                                },
                                "&.MuiAccordion-root::before": {
                                    display: "none",
                                },
                                borderRadius: "16px",
                            }}
                        >
                            <AccordionSummary
                                expandIcon={<ExpandMore />}
                                onClick={() => setMarketplacesAbertos(!marketplacesAbertos)}
                            >
                                <Typography variant="h6">
                                    Marketplaces
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                {!loadingMarketplaces && (
                                    <Autocomplete
                                        sx={{ marginTop: "1rem" }}
                                        multiple
                                        id="checkboxes-tags-demo"
                                        options={marketplaces}
                                        disableCloseOnSelect
                                        getOptionLabel={(option) => option?.nome ?? '-'}
                                        value={marketplacesSelecionados}
                                        onChange={handleMarketplacesChange}
                                        renderOption={(props, option, { selected }) => (
                                            <li {...props}>
                                                <Checkbox
                                                    icon={<CheckBoxOutlineBlank fontSize="small" />}
                                                    checkedIcon={<CheckBox fontSize="small" />}
                                                    style={{ marginRight: 8 }}
                                                    checked={marketplacesSelecionados.some(marketplace => 
                                                        (marketplace.uuid == option.uuid) || 
                                                        (marketplace.tipo == TipoMarketplace.PDV && option.tipo == TipoMarketplace.PDV)
                                                    )}
                                                />
                                                {option.nome} - {TipoMarketplaceTitulo[option.tipo]}
                                            </li>
                                        )}
                                        renderInput={(params) => (
                                            <TextField {...params} label="Marketplaces" placeholder="Selecione os marketplaces" />
                                        )}
                                    />
                                )}
                                <Alert severity={"info"} sx={{ marginTop: "1rem" }}>
                                    <AlertTitle>
                                        Como os marketplaces funcionam?
                                    </AlertTitle>
                                    Cada marketplace possui seu próprio cardápio.
                                    Dessa forma, você pode vender os mesmos produtos
                                    por preços diferentes dependendo do marketplace.
                                </Alert>
                            </AccordionDetails>
                        </Accordion>
                        {!categoria && (
                            <Accordion
                                expanded={modeloCategoriaAberto}
                                sx={{
                                    "&:before": {
                                        display: "none",
                                    },
                                    "&.MuiAccordion-root::before": {
                                        display: "none",
                                    },
                                    borderRadius: "16px",
                                }}
                            >
                                <AccordionSummary
                                    expandIcon={<ExpandMore />}
                                    onClick={() => setmodeloCategoriaAberto(!modeloCategoriaAberto)}
                                >
                                    <Typography variant="h6">
                                        Modelo
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <FormControl sx={{ width: '100%' }} className="header-input">
                                        <RadioGroup
                                            aria-labelledby="demo-radio-buttons-group-label"
                                            defaultValue="female"
                                            name="radio-buttons-group"
                                            value={modelo}
                                            onChange={(e, value) => setModelo(value as ModeloCategoria)}
                                            sx={{ gap: '1rem', width: '100%' }}
                                        >
                                            <RadioCard
                                                labelTitle="Itens principais"
                                                labelDescription="Comidas, lanches, sobremesas, etc."
                                                labelValue={ModeloCategoria.default}
                                                selected={modelo == ModeloCategoria.default}
                                                onClick={() => setModelo(ModeloCategoria.default)}
                                                key={ModeloCategoria.default}
                                                fullWidth
                                            />
                                            <RadioCard
                                                labelTitle="Pizza"
                                                labelDescription="Defina o tamanho, bordas e sabores"
                                                labelValue={ModeloCategoria.pizza}
                                                selected={modelo == ModeloCategoria.pizza}
                                                onClick={() => setModelo(ModeloCategoria.pizza)}
                                                key={ModeloCategoria.pizza}
                                                fullWidth
                                            />
                                        </RadioGroup>
                                    </FormControl>
                                </AccordionDetails>
                            </Accordion>
                        )}
                        {!marketplacesSelecionados?.some(marketplace => marketplacesOcultarPeriodoExibicao.includes(marketplace.tipo)) && (
                            <Accordion
                                expanded={periodosExibicaoAberto}
                                sx={{
                                    "&:before": {
                                        display: "none",
                                    },
                                    "&.MuiAccordion-root::before": {
                                        display: "none",
                                    },
                                    borderRadius: "16px",
                                }}
                            >
                                <AccordionSummary
                                    expandIcon={<ExpandMore />}
                                    onClick={() => setPeriodosExibicaoAberto(!periodosExibicaoAberto)}
                                >
                                    <Typography variant="h6">
                                        Período de exibição
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <PeriodoExibicaoCategoria
                                        adicionar={adicionarPeriodoExibicao}
                                        periodosExibicao={periodosExibicao}
                                        excluir={excluirPeriodoExibicao}
                                    />
                                </AccordionDetails>
                            </Accordion>
                        )}
                        {!marketplacesSelecionados?.some(marketplace => marketplacesOcultarContextos.includes(marketplace.tipo)) && (
                            <Accordion
                                expanded={contextosAbertos}
                                sx={{
                                    "&.Mui-expanded": {
                                        margin: 0,
                                    },
                                    "&:before": {
                                        display: "none",
                                    },
                                    "&.MuiAccordion-root::before": {
                                        display: "none",
                                    },
                                    borderRadius: "16px",
                                }}
                            >
                                <AccordionSummary
                                    expandIcon={<ExpandMore />}
                                    onClick={() => setContextosAbertos(!contextosAbertos)}
                                >
                                    <Typography variant="h6">
                                        Contextos
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Autocomplete
                                        sx={{ marginTop: "1rem" }}
                                        multiple
                                        id="checkboxes-tags-demo"
                                        options={Object.values(TipoPedido)}
                                        disableCloseOnSelect
                                        getOptionLabel={(option) => titulo(option)}
                                        value={contextos}
                                        onChange={handleContextosChange}
                                        renderOption={(props, option, { selected }) => (
                                            <li {...props}>
                                                <Checkbox
                                                    icon={<CheckBoxOutlineBlank fontSize="small" />}
                                                    checkedIcon={<CheckBox fontSize="small" />}
                                                    style={{ marginRight: 8 }}
                                                    checked={selected}
                                                />
                                                {titulo(option)}
                                            </li>
                                        )}
                                        renderInput={(params) => (
                                            <TextField {...params} label="Contextos" placeholder="Selecione os contextos" />
                                        )}
                                    />
                                    <Alert severity={"info"} sx={{ marginTop: "1rem" }}>
                                        <AlertTitle>
                                            Como os contextos funcionam?
                                        </AlertTitle>
                                        Na operacão o cardápio é segmentado em <strong>contextos</strong>.<br />
                                        A categoria só irá ser apresentada nos contextos definidos.
                                    </Alert>
                                </AccordionDetails>
                            </Accordion>
                        )}
                    </FormContainer>
                    <ResumoPaper>
                        <Typography variant="h6">Resumo</Typography>
                        <Paper variant="outlined" sx={{ padding: "1rem", marginTop: "2rem" }}>
                            <Typography
                                variant="subtitle1"
                            >
                                <b>Nome</b>: {watch("descricao")?.length ? watch("descricao") : "-"}
                            </Typography>
                            <Typography
                                variant="subtitle1"
                                sx={{ marginTop: "1rem" }}
                            >
                                <b>Posição</b>: {watch("posicao") ?? 0}
                            </Typography>
                        </Paper>
                        <Paper variant="outlined" sx={{ padding: "1rem", marginTop: "1rem" }}>
                            <Typography
                                variant="subtitle1"
                            >
                                <b>Marketplaces</b>: {marketplacesSelecionados?.length ? marketplacesSelecionados.map(marketplace => marketplace.nome).join(", ") : "-"}
                            </Typography>
                        </Paper>
                        <Paper variant="outlined" sx={{ padding: "1rem", marginTop: "1rem" }}>
                            <Typography
                                variant="subtitle1"
                            >
                                <b>Modelo</b>: {ModeloCategoriaTitulo[modelo]}
                            </Typography>
                        </Paper>
                        <Paper variant="outlined" sx={{
                            padding: "1rem",
                            marginTop: "1rem",
                            display: `flex`,
                            flexDirection: 'column'
                        }}>
                            <Typography
                                variant="subtitle1"
                            >
                                <b>Período de exibição</b>:
                            </Typography>
                            {marketplacesSelecionados?.some(marketplace => marketplacesOcultarPeriodoExibicao.includes(marketplace.tipo)) ? (
                                <Alert severity="info">Configuração indisponível para o marketplace selecionado</Alert>
                            ) : (
                                periodosExibicao?.map(periodo => {
                                    return (
                                        <Paper variant="outlined" sx={{
                                            padding: "0.5rem",
                                            marginTop: "0.5rem",
                                            display: `flex`,
                                            flexDirection: 'column'
                                        }}>
                                            <Typography>
                                                {titulo(periodo.diaSemana)}
                                            </Typography>
                                            {periodo?.horarios?.map(horario => {
                                                return <Typography color={"GrayText"} fontSize={12}>
                                                    {horario.inicio} - {horario.fim}
                                                </Typography>
                                            })}
                                        </Paper>
                                    );
                                })
                            )}
                        </Paper>
                        <Paper variant="outlined" sx={{ padding: "1rem", marginTop: "1rem" }}>
                            {marketplacesSelecionados?.some(marketplace => marketplacesOcultarContextos.includes(marketplace.tipo)) ? (
                                <>
                                    <Typography
                                        variant="subtitle1"
                                    >
                                        <b>Contextos</b>:
                                    </Typography>
                                    <Alert severity="info">Configuração indisponível para o marketplace selecionado</Alert>
                                </>
                            ) : (
                                <Typography
                                    variant="subtitle1"
                                >
                                    <b>Contextos</b>: {contextos?.length ? contextos.map(contexto => titulo(contexto)).join(", ") : "-"}
                                </Typography>
                            )}
                        </Paper>
                        <ButtonsContainer>
                            <Button size="large" variant="outlined" onClick={() => navigate("/cardapio")}>
                                Cancelar
                            </Button>
                            {loading ? (
                                <CircularProgress size={30} />
                            ) : (
                                <Button size="large" variant="contained" type="submit">
                                    {uuid ? "Atualizar" : "Cadastrar"}
                                </Button>
                            )}
                        </ButtonsContainer>
                    </ResumoPaper>
                </ContentContainer>
            </form>
        </PageContainer>
    );
}