import { Box, Button, Checkbox, FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { FiltrosContainer } from "./styles";
import { Controller, FieldValues, useForm } from "react-hook-form";
import { filtrosPedidosSchema } from "../../../../schemas/filtrosPedidosSchema";
import { yupResolver } from "@hookform/resolvers/yup";
import moment from "moment";
import { DatePicker } from "@mui/x-date-pickers";
import { Search } from "@mui/icons-material";
import { useSearchParams } from "react-router-dom";
import { useDataHora } from "../../../../../core/hooks/useDataHora";
import { useEffect } from "react";
import { TipoPedido } from "../../../../enum/TipoPedido";
import { useLetras } from "../../../../../core/hooks/useLetras";

export const FiltrosListagemPedidos = () => {
    const { setValue, control, reset, handleSubmit, register, formState: { errors } } = useForm({
        resolver: yupResolver(filtrosPedidosSchema),
        reValidateMode: 'onChange'
    });

    const { dataEua } = useDataHora();
    const { titulo } = useLetras();

    const limpar = () => {
        setValue('numeroPedido', undefined);
        setValue('tipos', []);
        setValue('dataInicio', moment());
        setValue('dataFim', moment());

        setSearchParams(state => {
            state.delete('numeroPedido')
            state.delete('dataInicio')
            state.delete('dataFim')
            state.delete('tipos')

            return state;
        });

    }

    const [searchParams, setSearchParams] = useSearchParams();

    const filtrar = (filtros: FieldValues) => {
        setSearchParams(state => {
            if (filtros.numeroPedido) {
                state.set('numeroPedido', filtros.numeroPedido)
            }

            if (filtros.dataInicio) {
                state.set('dataInicio', dataEua(filtros.dataInicio))
            }

            if (filtros.dataFim) {
                state.set('dataFim', dataEua(filtros.dataFim))
            }

            if (filtros.tipos) {
                state.set('tipos', filtros.tipos?.join(','))
            }

            return state;
        });
    }


    useEffect(() => {
        if (searchParams.get('numeroPedido')) {
            setValue('numeroPedido', searchParams.get('numeroPedido'));
        }

        if (searchParams.get('tipos')) {
            setValue('tipos', searchParams.get('tipos')?.split(','));
        } else {
            setSearchParams(state => {
                state.set('tipos', [
                    TipoPedido.delivery,
                    TipoPedido.retirada,
                    TipoPedido.simples,
                ].join(','));                
                return state;
            });
        }

        if (searchParams.get('dataInicio')) {
            setValue('dataInicio', moment(searchParams.get('dataInicio')));
        } else {
            setSearchParams(state => {
                state.set('dataInicio', dataEua());
                return state;
            });
        }

        if (searchParams.get('dataFim')) {
            setValue('dataFim', moment(searchParams.get('dataFim')));
        } else {
            setSearchParams(state => {
                state.set('dataFim', dataEua());
                return state;
            });
        }
    }, [searchParams])

    return (
        <FiltrosContainer>
            <form onSubmit={handleSubmit(filtrar)}>
                <TextField
                    label="Número do pedido"
                    size="small"
                    sx={{ marginRight: "1rem", maxWidth: "180px" }}
                    InputLabelProps={{ shrink: true }}
                    placeholder="Ex: 123"
                    {...register('numeroPedido')}
                    helperText={<>{errors.numeroPedido?.message}</>}
                    error={!!errors.numeroPedido}
                />
                <FormControl sx={{ marginRight: "1rem", maxWidth: "180px" }} error={!!errors.dataInicio}>
                    <Controller
                        defaultValue={moment()}
                        name="dataInicio"
                        control={control}
                        render={({ field }) => {
                            return (
                                <DatePicker
                                    format="DD/MM/YYYY"
                                    label="Data início"
                                    slotProps={{
                                        textField: {
                                            size: "small",
                                            error: !!errors.dataInicio,
                                            helperText: <>{errors?.dataInicio?.message}</>
                                        }
                                    }}
                                    value={moment(field.value)}
                                    defaultValue={moment()}
                                    onChange={(date) => {
                                        field.onChange(moment(date));
                                    }}
                                />
                            )
                        }}
                    />
                </FormControl>
                <FormControl error={!!errors.dataFim} sx={{ marginRight: "1rem", maxWidth: "180px" }} >
                    <Controller
                        defaultValue={moment()}
                        name="dataFim"
                        control={control}
                        render={({ field }) => {
                            return (
                                <DatePicker
                                    format="DD/MM/YYYY"
                                    label="Data final"
                                    slotProps={{
                                        textField: {
                                            size: "small",
                                            error: !!errors.dataFim
                                        }
                                    }}
                                    defaultValue={moment()}
                                    value={moment(field.value)}
                                    onChange={(date) => {
                                        field.onChange(moment(date));
                                    }}
                                />
                            )
                        }}
                    />
                </FormControl>
                <Controller
                    name="tipos"
                    control={control}
                    defaultValue={[TipoPedido.delivery, TipoPedido.retirada, TipoPedido.simples]} // Apenas os valores desejados
                    render={({ field }) => (
                        <FormControl sx={{ width: "180px", marginRight: "1rem" }} error={!!errors.tipos}>
                            <InputLabel error={!!errors.tipos} size="small" id="tipos-label">Contextos</InputLabel>
                            <Select
                                label="Contextos"
                                labelId="tipos-label"
                                id="tipos"
                                multiple
                                value={Array.isArray(field.value) ? field.value : []}
                                size="small"
                                onChange={field.onChange}
                                defaultValue={[TipoPedido.delivery, TipoPedido.retirada, TipoPedido.simples]} // Apenas os valores desejados
                                renderValue={(selected) => titulo(Array.isArray(selected) ? selected.join(', ') : '')} 
                                MenuProps={{
                                    PaperProps: {
                                        style: {
                                            maxHeight: 224,
                                            width: 250,
                                        },
                                    },
                                }}
                            >
                                {Object.values(TipoPedido).map((tipo) => (
                                    <MenuItem key={tipo} value={tipo}>
                                        <Checkbox checked={field?.value?.indexOf(tipo) > -1} />
                                        {titulo(tipo)}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    )}
                />
                <Button
                    variant="outlined"
                    onClick={limpar}
                    type="button"
                    sx={{ marginRight: "1rem" }}
                >
                    LIMPAR
                </Button>
                <Button type="submit" startIcon={<Search />} variant="contained">
                    FILTRAR
                </Button>
            </form>
        </FiltrosContainer>
    );
}