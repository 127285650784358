export enum TipoAlternativaNegociacaoPedido {
    'REEMBOLSO' = 'REEMBOLSO',
    'BENEFICIO' = 'BENEFICIO',
    'TEMPO_ADICIONAL' = 'TEMPO_ADICIONAL'
}

export enum TipoAlternativaNegociacaoPedidoDescricao {
    'REEMBOLSO' = 'Reembolso',
    'BENEFICIO' = 'Benefício',
    'TEMPO_ADICIONAL' = 'Tempo adicional'
}