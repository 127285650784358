export enum StatusPedido {
    "Aguardando confirmação" = 0,
    "Em preparo" = 1,
    "Em transporte" = 2,
    "Concluído" = 3,
    "Cancelado" = 4,
    "Em andamento" = 5,
    "Pronto para retirada" = 6,
    "Em negociação" = 7,
    "Aguardando resposta cliente" = 8
}

export enum StatusPedidoDescricao {
    "Aguardando confirmação" = "Aguardando confirmação",
    "Em preparo" = "Em preparo",
    "Em transporte" = "Em transporte",
    "Concluído" = "Concluído",
    "Cancelado" = "Cancelado",
    "Em andamento" = "Em andamento",
    "Pronto para retirada" = "Pronto para retirada",
    "Em negociação" = "Em negociação",
    "Aguardando resposta cliente" = "Aguardando resposta cliente"
}

export const StatusPedidosCores = {
    "Aguardando confirmação": "#FFD700", // Amarelo ouro
    "Em preparo": "#FF8C00", // Laranja escuro
    "Em transporte": "#1E90FF", // Azul forte (DodgerBlue)
    "Concluído": "#32CD32", // Verde limão
    "Cancelado": "#FF6347", // Vermelho tomate
    "Em andamento": "#9932CC", // Roxo escuro
    "Pronto para retirada": "#32CD32",
    "Em negociação": "#9932CC",
    "Aguardando resposta cliente": "#FFD700"
  };
  