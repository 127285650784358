import { Alert, Button, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Toolbar, Tooltip, Typography } from "@mui/material";
import { useState } from "react";
import { useCadastrarEditarProdutoContext } from "../../contexts/CadastrarEditarProdutoContext";
import { IComplemento } from "../../interfaces/IComplemento";
import { CadastrarComplementoContainer } from "../CadastrarComplementoContainer";
import { ButtonsContainerForm, ComplementosContainer as ComplementosContainerCard, FiltroContainer } from "./styles";
import { Add, Edit } from "@mui/icons-material";
import { useTamanhoTela } from "../../../core/hooks/useTamanhoTela";

interface ComplementosContainerProps { }

export const ComplementosContainer = ({ }: ComplementosContainerProps) => {
    const [tab, setTab] = useState<"tabela" | "formulario">("tabela");
    const [complementoEdicao, setComplementoEdicao] = useState<undefined | IComplemento>();
    const { handleVoltar, handleProximo, habilitarComplementos } = useCadastrarEditarProdutoContext();
    const { modeloTela } = useTamanhoTela();

    const abrirEdicaoComplemento = (complemento: IComplemento) => {
        setComplementoEdicao(complemento);
        setTab("formulario");
    }

    const voltar = () => {
        setComplementoEdicao(undefined);
        setTab("tabela");
    }

    const { complementos, salvarComplemento, excluirComplemento } = useCadastrarEditarProdutoContext();

    return (
        <ComplementosContainerCard>
            {tab == "tabela" && (
                <TableContainer variant='outlined' component={Paper} sx={{
                    width: "100%",
                    maxHeight: 450,
                    height: "100%",
                    minHeight: 350
                }}>
                    <Toolbar
                        sx={{
                            pl: { sm: 2 },
                            pr: { xs: 1, sm: 1 },
                            display: "flex",
                            justifyContent: "space-between"
                        }}>
                        <Typography variant="h6">
                            Complementos
                        </Typography>
                        <FiltroContainer>

                            <Tooltip title='Crie um novo complemento'>
                                <Button 
                                    startIcon={<Add />} 
                                    variant="contained" 
                                    color="primary" 
                                    onClick={() => setTab("formulario")}
                                    disabled={!habilitarComplementos}
                                >
                                    Cadastrar
                                </Button>
                            </Tooltip>
                        </FiltroContainer>
                    </Toolbar>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Nome</TableCell>
                                <TableCell>Número de Itens</TableCell>
                                <TableCell>Preenchimento obrigatório</TableCell>
                                <TableCell>Ações</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {complementos?.length === 0 ? (
                                <TableRow>
                                    <TableCell colSpan={4}>Nenhum complemento registrado</TableCell>
                                </TableRow>
                            ) : (
                                complementos?.map((complemento) => (
                                    <TableRow key={complemento.uuid}>
                                        <TableCell component="th" scope="row">
                                            {complemento.descricao}
                                        </TableCell>
                                        <TableCell>{complemento.itens?.length ?? 0}</TableCell>
                                        <TableCell>{complemento.obrigatorio ? "Sim" : "Não"}</TableCell>
                                        <TableCell>
                                            <IconButton onClick={() => abrirEdicaoComplemento(complemento)} aria-label="delete" size="small">
                                                <Edit fontSize="inherit" />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
            {tab == "formulario" && (
                <CadastrarComplementoContainer
                    complemento={complementoEdicao}
                    excluir={excluirComplemento}
                    salvar={salvarComplemento}
                    voltar={voltar}
                />
            )}
            {tab == "tabela" && (
                <ButtonsContainerForm>
                    <Button size="medium" variant="outlined" onClick={handleVoltar}>
                        Voltar
                    </Button>
                </ButtonsContainerForm>
            )}
        </ComplementosContainerCard>
    );
}