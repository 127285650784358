import { useMemo, useState } from "react";
import { ApiFactory } from "../../core/config/ApiFactory";
import { useSnackbar } from "../../core/contexts/SnackbarContext";
import { StatusPedido } from "../enum/StatusPedido";
import { TipoPedido } from "../enum/TipoPedido";
import { IPedido } from "../interface/IPedido";
import { IProdutoPedido } from "../interface/IProdutoPedido";
import { TipoAnexoPedido } from "../enum/TipoAnexoPedido";
import { MetodoPagamento } from "../../core/enum/MetodoPagamento";
import { IEndereco } from "../../core/interfaces/IEndereco";
import { TipoPessoa } from "../pages/Operacao/containers/EmitirNotaFiscalModal";
import { IDadosEmitirNotaFiscal } from "../interface/IDadosEmitirNotaFiscal";
import { IFiltrosPedidos } from "../interface/IFiltrosPedidos";
import { IPagamentoPedido } from "../interface/IPagamentoPedido";
import { TipoMarketplace } from "../../marketplace/enum/TipoMarketplace";
import { ICancelamentoPedido } from "../interface/ICancelamentoPedido";
import { IRespostaNegociacaoPedido } from "../interface/IRespostaNegociacaoPedido";

export const usePedidos = () => {
    const apiPedidos = useMemo(() => ApiFactory.getApi("MS_PEDIDOS"), []);
    const snackbar = useSnackbar();
    const [loading, setLoading] = useState(false);

    const gerarPedido = async (pedido: IPedido, tipo: TipoPedido) => {
        if(loading) {
            return null;
        }
        
        if(tipo == TipoPedido.delivery) {
            if(!pedido.cliente) {
                return snackbar("Cliente indefinido", { severity: "error" })
            }

            if(!pedido.endereco) {
                return snackbar("Endereço indefinido", { severity: "error" })
            }
        }

        if(tipo == TipoPedido.retirada) {
            if(!pedido.cliente) {
                return snackbar("Cliente indefinido", { severity: "error" })
            }
        }

        if(!pedido?.marketplace) {
            pedido.marketplace = {
                tipo: TipoMarketplace.PDV
            }
        }

        setLoading(true);
        try {
            const { data } = await apiPedidos.post("/", pedido);
            return data;
        } catch (error) {
            return null;
        } finally {
            setLoading(false);
        }
    }

    const listarPedidos = async (filtros: IFiltrosPedidos) => {
        setLoading(true);

        const params = new URLSearchParams();
        Object.entries(filtros).forEach(([key, value]) => {
            if (value) { 
                params.append(key, value instanceof Date ? value.toISOString() : value.toString());
            }
        });
        const queryString = params.toString();    
        
        try {
            const { data } = await apiPedidos.get(`/?${queryString}`);
            return data;
        } catch (error) {
            snackbar("Ocorreu um erro ao listar os pedidos", { severity: "error" })
            return [];
        } finally {
            setLoading(false);            
        }
    }

    const buscarPedido = async (pedidoUuid: string) => {
        if(loading) {
            return null;
        }

        if(!pedidoUuid) {
            snackbar("Pedido inválido", { severity: "error" })
            return null;
        }

        setLoading(true);

        try {
            const { data } = await apiPedidos.get(`/${pedidoUuid}`);
            return data;
        } catch (error) {
            snackbar("Pedido não encontrado", { severity: "error" })
            return null;
        } finally {
            setLoading(false);            
        }
    }

    const alterarEndereco = async (pedidoUuid: string, endereco: IEndereco) => {
        if(loading) {
            return false;
        }

        if(!pedidoUuid) {
            snackbar("Pedido inválido", { severity: "error" })
            return false;
        }

        if(!endereco) {
            snackbar("Endereço inválido", { severity: "error" })
            return false;
        }

        setLoading(true);
        try {
            const { data } = await apiPedidos.patch(`/${pedidoUuid}/endereco`, endereco);
            if(data) {
                return true;
            }
        } catch (error) {
            return false
        } finally {
            setLoading(false)
        }
    }

    const gerarAnexo = async (pedidoUuid: string, tipo: TipoAnexoPedido, configuracaoUuid: string, produtos?: IProdutoPedido[]) => {
        setLoading(true);
        try {
            const { data } = await apiPedidos.post(`/${pedidoUuid}/anexos/${tipo}/${configuracaoUuid}`, { produtos });
            if(data) {
                return true;
            }
        } catch (error) {
            return false;
        } finally {
            setLoading(false);
        }
    }

    const atualizarStatus = async ({
        pedidoUuid,
        status,
        cancelamento,
        pagamentos,
        respostaNegociacao
    }: {
        pedidoUuid: string, 
        status: StatusPedido | string, 
        pagamentos?: IPagamentoPedido[],
        cancelamento?: ICancelamentoPedido,
        respostaNegociacao?: IRespostaNegociacaoPedido
    }) => {
        setLoading(true);
        try {
            const { data } = await apiPedidos.patch(`/${pedidoUuid}/status/${status}`, { 
                pagamentos, 
                cancelamento,
                respostaNegociacao
            });
            return data;
        } catch (error) {
            return null;
        } finally {
            setLoading(false);
        }
    }

    const atualizarPagamentos = async (pedidoUuid: string, pagamentos: IPagamentoPedido[]) => {
        setLoading(true);
        try {
            const { data } = await apiPedidos.patch(`/${pedidoUuid}/pagamentos`, { pagamentos });
            return data;
        } catch (error) {
            return null;
        } finally {
            setLoading(false);
        }
    }

    const adicionarProduto = async (produto: IProdutoPedido, pedidoUuid: string) => {
        setLoading(true);
        try {
            const { data } = await apiPedidos.post(`/${pedidoUuid}/produto`, produto);
            return data;
        } catch (error) {
            return null;
        } finally {
            setLoading(false);
        }
    }

    const removerProduto = async (produtoUuid: string, pedidoUuid: string, quantidade?: number) => {
        setLoading(true);
        try {
            const { data } = await apiPedidos.delete(`/${pedidoUuid}/produto/${produtoUuid}/${quantidade ?? 1}`);
            return data;
        } catch (error) {
            return null;
        } finally {
            setLoading(false);
        }
    }

    const emitirNotaFiscal = async (
        pedidoUuid: string,
        dados?: IDadosEmitirNotaFiscal
    ) => {
        setLoading(true);
        try {
            const { data } = await apiPedidos.post(`/${pedidoUuid}/anexos/nfce`, dados);
            if(data) {
                return true;
            }
        } catch (error) {
            return false;
        } finally {
            setLoading(false);
        }
    }

    return {
        loading,
        gerarPedido,
        atualizarStatus,
        adicionarProduto,
        removerProduto,
        gerarAnexo,
        atualizarPagamentos,
        alterarEndereco,
        emitirNotaFiscal,
        buscarPedido,
        listarPedidos
    }
}