import styled from "@emotion/styled";
import { Box } from "@mui/material";

export const Container = styled(Box)`
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    height: 100%;
    flex-direction: column;
    padding: 3rem 3rem 5rem 3rem;
    margin: 0 auto;
`

export const HeaderContainer = styled(Box)`
    width: 100%;
    display: flex;
    justify-content: space-between;
`

export const CardContagem = styled(Box)`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 1rem;
    width: 200px;
`