import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, FormControl, FormControlLabel, FormHelperText, FormLabel, InputAdornment, InputLabel, MenuItem, OutlinedInput, Radio, RadioGroup, Select, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { CardUploadImagem } from "../../../core/components/CardUploadImagem";
import { useCadastrarEditarProdutoContext } from "../../contexts/CadastrarEditarProdutoContext";
import { IImagemProduto } from "../../interfaces/IImagemProduto";
import { preparadoSchema } from "../../schema/preparadoSchema";
import { ImagemContainer } from "../ConfiguracaoPizzaContainer/styles";
import { ButtonsContainerForm, ConfiguracaoContainer, Formulario, FormularioPaper } from "./styles";

interface ConfiguracaoPreparadoContainerProps { }

export const ConfiguracaoPreparadoContainer = ({ }: ConfiguracaoPreparadoContainerProps) => {
    const [imagem, setImagem] = useState<undefined | File | IImagemProduto>(undefined);

    const { reset, register, handleSubmit, watch, setValue, formState: { errors } } = useForm({
        mode: "onChange",
        resolver: yupResolver(preparadoSchema)
    });

    const {
        setHabilitarComplementos,
        setHabilitarObservacao,
        handleExcluirImagem,
        handleUploadImagem,
        descricao,
        habilitarComplementos,
        habilitarObservacao,
        preco,
        detalhes,
        registrarConfiguracoesPreparado,
        setDescricao,
        setPreco,
        setDetalhes,
        setServe,
        serve,
        imagens
    } = useCadastrarEditarProdutoContext();

    useEffect(() => {
        if (imagens?.length) {
            setImagem(imagens[0]);
        } else {
            setImagem(undefined);
        }
    }, [imagens])

    return (
        <ConfiguracaoContainer>
            <FormularioPaper onSubmit={handleSubmit(registrarConfiguracoesPreparado)}>
                <Formulario>
                    <Typography
                        variant="h6"
                        sx={{ width: "100%", marginBottom: "1rem" }}
                    >
                        Configuração
                    </Typography>
                    <FormControl sx={{ marginTop: "1rem" }} fullWidth>
                        <TextField
                            label="Nome"
                            {...register("descricao")}
                            onChange={(e) => setDescricao(e.target.value)}
                            value={descricao}
                            error={!!errors.descricao}
                            helperText={<>{errors.descricao?.message}</>}
                            InputLabelProps={{ shrink: true }}
                            placeholder="Ex: Hamburguer, macarrão, etc..."
                        />
                    </FormControl>
                    <FormControl sx={{ marginTop: "1.5rem" }}>
                        <FormLabel id="habilitar-complementos-label">Habilitar complementos</FormLabel>
                        <RadioGroup
                            row
                            aria-labelledby="habilitar-complementos-label"
                            name="habilitar-complementos-label-group"
                            value={habilitarComplementos}
                            onChange={() => setHabilitarComplementos(!habilitarComplementos)}
                        >
                            <FormControlLabel value={false} control={<Radio />} label="Não" />
                            <FormControlLabel value={true} control={<Radio />} label="Sim" />
                        </RadioGroup>
                    </FormControl>

                    <FormControl sx={{ marginTop: "1.5rem" }}>
                        <FormLabel id="habilitar-observacao-label">Habilitar observação</FormLabel>
                        <RadioGroup
                            row
                            aria-labelledby="habilitar-observacao-label"
                            name="habilitar-observacao-label-group"
                            value={habilitarObservacao}
                            onChange={() => setHabilitarObservacao(!habilitarObservacao)}
                        >
                            <FormControlLabel value={false} control={<Radio />} label="Não" />
                            <FormControlLabel value={true} control={<Radio />} label="Sim" />
                        </RadioGroup>
                    </FormControl>
                    <FormControl sx={{ marginTop: "1.5rem" }} fullWidth>
                        <InputLabel error={!!errors.preco} htmlFor="preco-industrializado">Preço</InputLabel>
                        <OutlinedInput
                            id="preco-preparado"
                            startAdornment={<InputAdornment position="start">R$</InputAdornment>}
                            label="Preço"
                            error={!!errors.preco}
                            value={preco}
                            {...register('preco')}
                            placeholder="Preço"
                            onChange={(e) => setPreco(e.target.value)}
                        />
                        {!!errors.preco && <FormHelperText error={true}><>{errors.preco?.message}</></FormHelperText>}
                    </FormControl>
                    <FormControl sx={{ marginTop: "1.5rem" }} fullWidth>
                        <InputLabel htmlFor="serve-select">Quantidade de pessoas servidas</InputLabel>
                        <Select
                            labelId="serve-select"
                            id="serve-select"
                            value={serve ?? 0}
                            onChange={(e) => setServe(e.target.value == undefined ? undefined : Number(e.target.value) )}
                            label="Quantidade de pessoas servidas"
                        >
                            <MenuItem value={0}>
                                <em>Não aplicável</em>
                            </MenuItem>
                            {[1, 2, 3, 4].map((option) => (
                                <MenuItem key={option} value={option}>
                                    {option}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl sx={{ marginTop: "1.5rem" }} fullWidth>
                        <TextField
                            multiline
                            label="Detalhes"
                            {...register("detalhes")}
                            value={detalhes}
                            error={!!errors.detalhes}
                            helperText={<>{errors.detalhes?.message}</>}
                            InputLabelProps={{ shrink: true }}
                            placeholder="Ex: Delicioso hamburguer bovino de 200g..."
                            onChange={(e) => setDetalhes(e.target.value)}
                            rows={3}
                        />
                        {!!errors.detalhes && <FormHelperText error={true}><>{errors.detalhes?.message}</></FormHelperText>}
                    </FormControl>
                    <ImagemContainer>
                        <CardUploadImagem
                            titulo="Imagem"
                            descricao={
                                imagem
                                    ? (imagem instanceof File ? imagem?.name : (imagem?.nomeOriginal ?? imagem?.nome))
                                    : "Faça o upload"
                            }
                            url={imagem instanceof File ? URL.createObjectURL(imagem) : imagem?.url}
                            excluir={handleExcluirImagem}
                            upload={handleUploadImagem}
                        />
                    </ImagemContainer>
                    <ButtonsContainerForm>
                        <Box></Box>
                        <Button size="medium" variant="contained" type="submit">
                            Próximo
                        </Button>
                    </ButtonsContainerForm>
                </Formulario>
            </FormularioPaper>
        </ConfiguracaoContainer>
    );
}