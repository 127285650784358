import { Alert, AlertTitle, Box, Button, Paper, Typography } from "@mui/material"
import { IIntegracaoIFood, IIntegracaoMarketplace } from "../../../interfaces/IIntegracaoMarketplace";
import { Delete } from "@mui/icons-material";
import { DadoContainer } from "./styles";
import { useMarketplaces } from "../../../hooks/useMarketplaces";
import { IMarketplace } from "../../../interfaces/IMarketplace";
import { useState } from "react";
import { ConfirmacaoAcaoModal } from "../../../../core/components/ConfirmacaoAcaoModal";
import { ConfirmarAcaoTextoModal } from "../../../../core/components/ConfirmarAcaoTextoModal";

interface VinculoIFoodPaperProps {
    marketplace: IMarketplace
    refresh: () => void;
}

export const VinculoIFoodPaper = ({ marketplace, refresh }: VinculoIFoodPaperProps) => {
    const { salvar, loading } = useMarketplaces();
    const integracao = marketplace?.configuracao?.integracao as IIntegracaoIFood;

    const [open, setOpen] = useState(false);
    const abrirExclusao = () => setOpen(true);
    const fecharExclusao = () => setOpen(false);
    const excluir = async () => {
        await salvar({
            ...marketplace,
            configuracao: {
                ...marketplace.configuracao,
                integracao: undefined
            }
        });
        refresh();
    }

    return (
        <Paper sx={{ width: "100%", p: 2, minHeight: "180px", height: "100%" }}>
            <ConfirmarAcaoTextoModal 
                loading={loading}
                open={open}
                cancelar={fecharExclusao}
                confirmar={excluir}
                mensagem={<Typography>Para confirmar a exclusão, digite <b>Excluir integração</b> no campo.</Typography>}
                titulo="Excluir integração iFood"
                parametro="Excluir integração"
            />
            <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%", mb: "1rem" }}>
                <Typography variant="h6">Integração</Typography>
                <Button onClick={abrirExclusao} endIcon={<Delete />}>
                    Excluir
                </Button>
            </Box>
            {!integracao.merchantId || !integracao.catalogId ? (
                <Alert severity="warning">
                    <AlertTitle>Integração incompleta</AlertTitle>
                    {!integracao.merchantId && "O estabelecimento não foi configurado"}
                    {!integracao.catalogId && "O catálogo não foi configurado"}
                    <br />
                    <br />
                    Realize a reintegração para solucionar o problema identificado.
                </Alert>
            ) : (
                <Alert severity="success">
                    <AlertTitle>Integração realizada com sucesso</AlertTitle>
                    Agora você está pronto para receber os pedidos através do iFood.
                </Alert>
            )}

            <DadoContainer>
                <Typography>Merchant ID</Typography>
                <Typography color={"GrayText"} sx={{
                    width: "100%",
                    fontSize: "14px"
                }}>
                    {integracao?.merchantId ?? "Não definido"}
                </Typography>
            </DadoContainer>
            <DadoContainer>
                <Typography>Catalog ID</Typography>
                <Typography color={"GrayText"} sx={{
                    width: "100%",
                    fontSize: "14px"
                }}>
                    {integracao?.catalogId ?? "Não definido"}
                </Typography>
            </DadoContainer>
        </Paper>
    );
}