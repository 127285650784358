import AddIcon from '@mui/icons-material/Add';
import CheckIcon from '@mui/icons-material/Check';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Button, IconButton,  Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Toolbar, Tooltip, Typography } from "@mui/material";
import { useState } from "react";
import { useMoeda } from "../../../core/hooks/useMoeda";
import { useCadastrarEditarProdutoContext } from "../../contexts/CadastrarEditarProdutoContext";
import { IMassa } from "../../interfaces/IMassa";
import { ButtonsContainerForm, ConfiguracaoBordas, FiltroContainer, TabelaVazia } from "./styles";
import { useTamanhoTela } from '../../../core/hooks/useTamanhoTela';
import { FormularioMassa } from './containers/FormularioMassa';
import { IImagemProduto } from '../../interfaces/IImagemProduto';

type Tabs = "tabela" | "formulario";

interface ConfiguracaoFormularioContainerProps { }

export const ConfiguracaoMassasContainer = ({ }: ConfiguracaoFormularioContainerProps) => {
    const [filtroMassas, setFiltroMassas] = useState("");
    const { massas, adicionarMassa, excluirMassa, editarMassa, handleProximo, handleVoltar } = useCadastrarEditarProdutoContext();
    const [tab, setTab] = useState<Tabs>("tabela");
    const [massaEdicao, setMassaEdicao] = useState<IMassa | undefined>(undefined)
    const [confirmacaoExclusao, setConfirmacaoExclusao] = useState<string | undefined>(undefined);
    const { real } = useMoeda();
    const { modeloTela } = useTamanhoTela();

    const handleTab = (tab: Tabs) => {
        setTab(tab)
        setMassaEdicao(undefined);
    }

    const handleExcluirMassa = async (massa: IMassa) => {
        excluirMassa(massa);
        setConfirmacaoExclusao(undefined);
        handleTab("tabela");
    }

    const handleAbrirEdicao = (massa: IMassa) => {
        setTab("formulario");
        setMassaEdicao(massa);
    }

    const renderizarLinha = (massa: IMassa) => {
        return (
            <TableRow>
                <TableCell align="center">
                    <img alt={massa.descricao} src={
                        massa?.imagens?.length
                        ? massa?.imagens[0] instanceof File ? URL.createObjectURL(massa?.imagens[0]) : massa?.imagens[0].url
                        : `${process.env.PUBLIC_URL}/images/produtos/generico.png`
                    } style={{
                        width: "80px",
                        height: "70px",
                        borderRadius: "5px"
                    }}/>
                </TableCell>
                <TableCell align="center">{massa.descricao}</TableCell>
                <TableCell align="center">{real(massa.preco)}</TableCell>
                <TableCell align="center">
                    <IconButton onClick={() => handleAbrirEdicao(massa)} aria-label="delete" size="small">
                        <EditIcon fontSize="inherit" />
                    </IconButton>
                </TableCell>
                <TableCell align="center">
                    {confirmacaoExclusao && confirmacaoExclusao == massa.uuid ? (
                        <IconButton onClick={() => handleExcluirMassa(massa)} aria-label="delete" size="small">
                            <CheckIcon fontSize="inherit" />
                        </IconButton>
                    ) : (
                        <IconButton onClick={() => setConfirmacaoExclusao(massa.uuid)} aria-label="delete" size="small">
                            <DeleteIcon fontSize="inherit" />
                        </IconButton>
                    )}
                </TableCell>
            </TableRow>
        );
    }

    return (
        <ConfiguracaoBordas>
            {tab == "tabela" && (
                <TableContainer variant='outlined' component={Paper} sx={{
                    width: "100%",
                    maxHeight: 450,
                    height: "100%",
                    minHeight: 350
                }}>
                    <Toolbar
                        sx={{
                            pl: { sm: 2 },
                            pr: { xs: 1, sm: 1 },
                            display: "flex",
                            justifyContent: "space-between"
                        }}>
                        <Typography variant="h6">
                            Massas
                        </Typography>
                        <FiltroContainer>
                            {modeloTela == "desktop" && (
                                <TextField
                                    placeholder='Nome'
                                    size='small'
                                    value={filtroMassas}
                                    onChange={(e) => setFiltroMassas(e.target.value)}
                                    sx={{ width: "200px" }}
                                />
                            )}
                            <Tooltip title='Crie uma nova massa'>
                                <Button startIcon={<AddIcon />} variant="contained" color="primary" onClick={() => handleTab("formulario")}>
                                    Cadastrar
                                </Button>
                            </Tooltip>
                        </FiltroContainer>
                    </Toolbar>
                    {massas.length ? (
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center">Imagem</TableCell>
                                    <TableCell align="center">Nome</TableCell>
                                    <TableCell align="center">Preço</TableCell>
                                    <TableCell align="center">Editar</TableCell>
                                    <TableCell align="center">Excluir</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {filtroMassas
                                    ? massas.filter(massa => massa.descricao.toLowerCase().includes(filtroMassas.toLocaleLowerCase())).map(massa => {
                                        return renderizarLinha(massa);
                                    })
                                    : massas.map(massa => {
                                        return renderizarLinha(massa);
                                    })}

                            </TableBody>
                        </Table>
                    ) : (
                        <TabelaVazia>
                            Nenhuma massa cadastrada
                        </TabelaVazia>
                    )}
                </TableContainer>
            )}
            {tab == "formulario" && <FormularioMassa 
                adicionarMassa={adicionarMassa}
                editarMassa={editarMassa}
                handleTab={handleTab}
                massaEdicao={massaEdicao}
            />}
            {tab == "tabela" && (
                <ButtonsContainerForm>
                    <Button size="medium" variant="outlined" onClick={handleVoltar}>
                        Voltar
                    </Button>
                    <Button size="medium" variant="contained" onClick={handleProximo}>
                        Próximo
                    </Button>
                </ButtonsContainerForm>
            )}
        </ConfiguracaoBordas>
    )
}