import { useEffect, useState } from "react";
import { useDataHora } from "../../../../../../../../../core/hooks/useDataHora";
import { Alert, AlertTitle, Typography } from "@mui/material";

interface TemposNegociacaoProps { 
    expiradoAs: string;
    criadoAs: string;
}

export const TemposNegociacao = ({ criadoAs, expiradoAs }: TemposNegociacaoProps) => {
    const { dataHoraBr, dataEhDepois } = useDataHora();
    const [tempoRestante, setTempoRestante] = useState<number | null>(null);
  
    useEffect(() => {
      const agora = new Date();
      if (!dataEhDepois(expiradoAs, agora)) {
        setTempoRestante(new Date(expiradoAs).getTime() - agora.getTime());
      } else {
        setTempoRestante(null);
      }
    }, [expiradoAs, dataEhDepois]);
  
    useEffect(() => {
      if (tempoRestante === null) return;
  
      const timer = setInterval(() => {
        setTempoRestante((prev) => {
          if (prev !== null && prev <= 1000) {
            clearInterval(timer);
            return null;
          }
          return prev ? prev - 1000 : null;
        });
      }, 1000);
  
      return () => clearInterval(timer);
    }, [tempoRestante]);
  
    const formatarTempoRestante = (ms: number) => {
        const segundos = Math.floor((ms / 1000) % 60);
        const minutos = Math.floor((ms / 1000 / 60) % 60);
        return `${minutos}m ${segundos}s`;
    };

    if(tempoRestante == null) {
        return (
        <Alert severity="info" sx={{ marginTop: "1rem" }}>
            <AlertTitle>Tempo expirado</AlertTitle>
            Criado às {dataHoraBr(criadoAs)}, expirado às {dataHoraBr(expiradoAs)}.
        </Alert>
        );
    }

    return (
        <Alert severity="warning" sx={{ marginTop: "1rem" }}>
            Expira em {formatarTempoRestante(tempoRestante)}
        </Alert>
    );
}