import { Alert, Box, Card, Drawer, IconButton, RadioGroup, Typography } from "@mui/material";
import { IPedido } from "../../../../../../interface/IPedido";
import { StatusPedido, StatusPedidoDescricao } from "../../../../../../enum/StatusPedido";
import { AlternativasNegociacaoCard, CardContainer, DrawerContainer, DrawerContent, DrawerHeader, Item, ItensContainerCard, MensagemNegociacaoCard, NegociacaoCard, NegociacaoCardHeader, TemposNegociacaoCard } from "./styles";
import { AccessAlarm, ChevronLeft, Close, Message, Payments, PendingActions } from "@mui/icons-material";
import { AcaoNegociacaoPedidoDescricao } from "../../../../../../enum/AcaoNegociacaoPedido";
import { TipoNegociacaoPedido, TipoNegociacaoPedidoDescricao } from "../../../../../../enum/TipoNegociacaoPedido";
import { useDataHora } from "../../../../../../../core/hooks/useDataHora";
import { NegociacaoReembolsoParcial } from "./components/NegociacaoReembolsoParcial";
import { NegociacaoReembolsoTotal } from "./components/NegociacaoReembolsoTotal";
import { NegociacaoEmPreparacao } from "./components/NegociacaoEmPreparacao";
import { usePedidos } from "../../../../../../hooks/usePedidos";
import { TipoRespostaNegociacaoPedido } from "../../../../../../enum/TipoRespostaNegociacaoPedido";
import { TipoAlternativaNegociacaoPedido } from "../../../../../../enum/TipoAlternativaNegociacaoPedido";

interface NegociacaoPedidoProps {
  pedidosSelecionados: IPedido[];
  atualizarListagem: () => void;
  desselecionarPedido: (pedido: IPedido) => void;
}

export const NegociacaoPedido = ({ pedidosSelecionados, desselecionarPedido, atualizarListagem }: NegociacaoPedidoProps) => {
  const { dataEhDepois } = useDataHora();
  const pedido = pedidosSelecionados[0];
  const negociacao = pedido?.negociacoes
  ?.slice()
  ?.sort((a, b) => (dataEhDepois(b.criadoAs, a.criadoAs) ? -1 : 1))[0];
  const { atualizarStatus, loading } = usePedidos();

  const aceitarNegociacao = async (pedidoUuid: string) => {
    if(loading) return;

    const statusAtualizado = await atualizarStatus({
      pedidoUuid,
      status: StatusPedidoDescricao["Aguardando resposta cliente"],
      respostaNegociacao: {
        tipo: TipoRespostaNegociacaoPedido.ACEITA
      }
    })

    if(statusAtualizado) {
      desselecionarPedido(pedido);
      setTimeout(() => atualizarListagem(), 1000)
    }
  }

  const recusarNegociacao = async (pedidoUuid: string, motivo: string) => {
    if(loading) return;

    const statusAtualizado = await atualizarStatus({
      pedidoUuid,
      status: StatusPedidoDescricao["Aguardando resposta cliente"],
      respostaNegociacao: {
        tipo: TipoRespostaNegociacaoPedido.RECUSADA,
        motivo
      }
    })

    if(statusAtualizado) {
      desselecionarPedido(pedido);
      setTimeout(() => atualizarListagem(), 1000)
    }
  }


  const efetuarContrapropostaNegociacao = async (pedidoUuid: string, valor: number) => {
    if(loading) return;

    const statusAtualizado = await atualizarStatus({
      pedidoUuid,
      status: StatusPedidoDescricao["Aguardando resposta cliente"],
      respostaNegociacao: {
        tipo: TipoRespostaNegociacaoPedido.CONTRAPROPOSTA,
        metadados: {
          tipo: TipoAlternativaNegociacaoPedido.REEMBOLSO,
          valor
        }
      }
    })

    if(statusAtualizado) {
      desselecionarPedido(pedido);
      setTimeout(() => atualizarListagem(), 1000)
    }
  }


  return (
    <Drawer
      anchor={"right"}
      onClose={() => desselecionarPedido(pedido)}
      open={pedidosSelecionados?.length == 1 && pedido?.status == StatusPedido["Em negociação"]}
    >
      <DrawerContainer>
        <DrawerHeader>
          <Box>
            <Typography variant="h6">
              Negociação em andamento
            </Typography>
            <Typography variant="subtitle1" color="GrayText">
              Pedido #{pedido?.numeroPedido}
            </Typography>
          </Box>
          <IconButton onClick={() => desselecionarPedido(pedido)}>
            <Close />
          </IconButton>
        </DrawerHeader>
        <DrawerContent>
          {negociacao?.tipo == TipoNegociacaoPedido.APOS_DELIVERY_PARCIAL && (
            <NegociacaoReembolsoParcial 
              negociacao={negociacao}
              pedido={pedido}
              aceitarNegociacao={aceitarNegociacao}
              recusarNegociacao={recusarNegociacao}
              efetuarContrapropostaNegociacao={efetuarContrapropostaNegociacao}
              loading={loading}
            />
          )}
          {negociacao?.tipo == TipoNegociacaoPedido.APOS_DELIVERY && (
            <NegociacaoReembolsoTotal 
              negociacao={negociacao}
              pedido={pedido}
              aceitarNegociacao={aceitarNegociacao}
              recusarNegociacao={recusarNegociacao}
              efetuarContrapropostaNegociacao={efetuarContrapropostaNegociacao}
              loading={loading}
            />
          )}
          {negociacao?.tipo == TipoNegociacaoPedido.TEMPO_PREPARACAO && (
            <NegociacaoEmPreparacao 
              negociacao={negociacao}
              pedido={pedido}
              aceitarNegociacao={aceitarNegociacao}
              recusarNegociacao={recusarNegociacao}
              loading={loading}
            />
          )}
        </DrawerContent>
      </DrawerContainer>
    </Drawer>
  );
}