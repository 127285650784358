import * as yup from "yup";

export const filtrosPedidosSchema = yup.object().shape({
    dataInicio: yup
        .string()
        .required("A data de início é obrigatória")
        .test("validDataInicio", "A data de início deve ser anterior ou igual à data final", function (value) {
            const { dataFim } = this.parent;
            if (!value || !dataFim) return true; // Se uma das datas não estiver presente, valida como true
            const dataInicioValida = Date.parse(value); 
            const dataFimValida = Date.parse(dataFim);
            return dataInicioValida <= dataFimValida; // Permitir que sejam iguais ou anterior
        }),
    dataFim: yup
        .string()
        .required("A data final é obrigatória")
        .test("validDataFim", "A data final deve ser posterior ou igual à data de início", function (value) {
            const { dataInicio } = this.parent;
            if (!value || !dataInicio) return true; // Se uma das datas não estiver presente, valida como true
            const dataFimValida = Date.parse(value);
            const dataInicioValida = Date.parse(dataInicio);
            return dataFimValida >= dataInicioValida; // Permitir que sejam iguais ou posterior
        }),
    numeroPedido: yup
        .string()
        .max(20, "Número de pedido inválido"),
    tipos: yup
        .array()
        .min(1, "Pelo menos um tipo é obrigatório")
});
