import { AccordionDetails, Alert, AlertTitle, Box, Button, CircularProgress, TextField, Typography } from "@mui/material";
import { useState } from "react";

interface SegundaEtapaVinculoIFoodProps {
    authorizationCode: string;
    setAuthorizationCode: (code: string) => void;
    vincular: (code: string) => void;
    loading: boolean;
    voltar: () => void;
}

export const SegundaEtapaVinculoIFood = ({ authorizationCode, setAuthorizationCode, vincular, loading, voltar }: SegundaEtapaVinculoIFoodProps) => {
    return (
        <Box>
            <Typography variant="h6">Autorização</Typography>
            <Typography variant="subtitle2" fontWeight={400} color={"GrayText"} sx={{ mt: "1rem" }}>
                Para prosseguir, clique no botão <strong>"Autorizar"</strong> no portal do parceiro iFood.
                Em seguida, copie e cole o código gerado no campo abaixo.
            </Typography>
            <TextField
                label="Código de Autorização"
                style={{ width: "100%", marginTop: "1rem" }}
                value={authorizationCode}
                onChange={(e) => setAuthorizationCode(e.target.value)}
            />
            <Box sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
                marginTop: '1rem'
            }}>
                <Button onClick={voltar} variant="outlined" color="primary" sx={{ mr: "0.5rem" }}>
                    Voltar
                </Button>
                <Button onClick={() => vincular(authorizationCode)} variant={loading ? "text" : "contained"} color="primary">
                    {loading ? <CircularProgress size={25} color="inherit" /> : "Enviar Código"}
                </Button>
            </Box>
        </Box>
    );
};
