import AddIcon from '@mui/icons-material/Add';
import { Button, CircularProgress, Tooltip, Typography } from "@mui/material";
import { useNavigate, useSearchParams } from 'react-router-dom';
import { CategoriaCard } from '../../containers/CategoriaCard';
import { useCategorias } from '../../hooks/useCategorias';
import { useFetchCategorias } from "../../hooks/useFetchCategorias";
import { AccordionContainer, CadastrarCategoriaContainer, CardapioContainer, LoadingContainer, NenhumaCategoriaContainer } from "./styles";
import { useAutenticacaoContext } from '../../../core/contexts/AutenticacaoContext';
import { useEffect } from 'react';
import { useMarketplaces } from '../../../marketplace/hooks/useMarketplaces';
import { useFetchMarketplaces } from '../../../marketplace/hooks/useFetchMarketplaces';
import { IMarketplace } from '../../../marketplace/interfaces/IMarketplace';
import { TipoMarketplace } from '../../../marketplace/enum/TipoMarketplace';
import { ICategoria } from '../../interfaces/ICategoria';

export const Cardapio = () => {
    const navigate = useNavigate();
    const { excluir: excluirCategoria } = useCategorias();

    const { categorias, isLoading, handleRefresh } = useFetchCategorias();

    const { verificarAcessoFuncionalidade, perfil } = useAutenticacaoContext();
    useEffect(() => {
        if (perfil && !verificarAcessoFuncionalidade("Listagem de categorias")) {
            navigate("/nao-autorizado")
        }
    }, [perfil])

    const { marketplaces, loading: loadingMarketplaces } = useFetchMarketplaces();
    const [searchParams, setSearchParams] = useSearchParams();
    
    useEffect(() => {
        if (categorias && categorias.length > 0) {
            const firstCategoriaUuid = categorias[0].uuid;
            const currentCategoriaUuid = searchParams.get('categoriaUuid');
            if (!currentCategoriaUuid && firstCategoriaUuid) {
                searchParams.set('categoriaUuid', firstCategoriaUuid);
                setSearchParams(searchParams);
            }
        }
    }, [categorias, searchParams, setSearchParams]);

    async function handleExcluirCategoria(categoria: ICategoria) {
        const resposta = await excluirCategoria(categoria.uuid as string);
        return resposta;
    }

    return (
        <CardapioContainer>
            <CadastrarCategoriaContainer>
                <Tooltip title='Cadastre uma nova categoria'>
                    <Button
                        startIcon={<AddIcon />}
                        onClick={() => navigate('/cardapio/categorias')}
                        variant="contained"
                        color="primary"
                    >
                        Categoria
                    </Button>
                </Tooltip>
            </CadastrarCategoriaContainer>
            {isLoading ? (
                <LoadingContainer>
                    <CircularProgress />
                </LoadingContainer>
            ) : (
                categorias && marketplaces && !loadingMarketplaces && categorias.length > 0 ? (
                    categorias.map(categoria => {
                        const marketplacesCategoria: IMarketplace[] = [];

                        categoria.marketplaces?.map(marketplaceCategoria => {
                            const marketplaceEncontrado = marketplaces?.find(marketplace => {
                                if((marketplace.tipo == marketplaceCategoria.tipo) && marketplace.tipo == TipoMarketplace.PDV) {
                                    return marketplace;
                                }

                                if(marketplace.uuid == marketplaceCategoria.uuid) {
                                    return marketplace;
                                }
                            })

                            if(marketplaceEncontrado) marketplacesCategoria.push(marketplaceEncontrado);
                        })

                        return (
                            <AccordionContainer key={categoria.uuid}>
                                <CategoriaCard
                                    handleRefresh={handleRefresh}
                                    excluirCategoria={handleExcluirCategoria}
                                    categoria={categoria}
                                    categorias={categorias}
                                    marketplaces={marketplacesCategoria}
                                />
                            </AccordionContainer>
                        )
                    })
                ) : (
                    <NenhumaCategoriaContainer>
                        <img src={`${process.env.PUBLIC_URL}/images/astronaut.png`} />
                        <Typography variant='h5' sx={{ marginTop: "2rem" }}>
                            Nenhuma categoria criada
                        </Typography>
                        <Typography variant='subtitle1' sx={{ marginTop: "0.5rem" }}>
                            Monte seu cardápio de forma simples e intuitiva
                        </Typography>
                    </NenhumaCategoriaContainer>
                )
            )}
        </CardapioContainer>
    )
}