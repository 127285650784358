import { Button, CircularProgress, Typography } from "@mui/material";
import { Tools, ToolsContainer, ToolsTitle } from "../../styles";
import { StatusPedidoDescricao } from "../../../../../../enum/StatusPedido";
import { IMarketplace } from "../../../../../../../marketplace/interfaces/IMarketplace";

interface ToolsEmPreparoRetiradaProps {
    quantidadePedidosSelecionados: number;
    loading: boolean;
    atualizarStatus: (status: StatusPedidoDescricao) => void;
    abrirCancelamento: () => void;
    marketplace: IMarketplace;
}

export const ToolsEmPreparoRetirada = ({
    atualizarStatus,
    loading,
    quantidadePedidosSelecionados,
    marketplace,
    abrirCancelamento
}: ToolsEmPreparoRetiradaProps) => {
    return (
        <Tools>
            <ToolsTitle>
                <Typography variant="subtitle1">
                    {quantidadePedidosSelecionados == 1 ? `1 pedido selecionado` : `${quantidadePedidosSelecionados} pedidos selecionados`}
                </Typography>
            </ToolsTitle>
            <ToolsContainer>
                {loading ? (
                    <CircularProgress size={15} />
                ) : (
                    <>
                        <Button 
                            variant="outlined" 
                            disabled={quantidadePedidosSelecionados != 1}
                            onClick={abrirCancelamento}
                        >
                            Cancelar pedido
                        </Button>
                        <Button
                            variant="contained"
                            onClick={() => atualizarStatus(StatusPedidoDescricao["Pronto para retirada"])}
                        >
                            Pronto(s) para coleta
                        </Button>
                    </>
                )}
            </ToolsContainer>
        </Tools>
    );
}