import styled from "@emotion/styled";
import { Box } from "@mui/material";

export const DadoContainer = styled(Box)`
    margin-top: 1rem;
    padding: 0.5rem 0; 
    display: flex; 
    width: 100%;
    border-bottom: 1px dashed ${props => props.theme.palette.divider};
    flex-direction: column;
    gap: 0.2rem;
    
    &:last-child {
        border-bottom: none;
    }
`