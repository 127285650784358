import { Box, Button, Card, CircularProgress, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { AlternativasNegociacaoCard, Item, ItensContainerCard, MensagemNegociacaoCard, NegociacaoCard, NegociacaoCardHeader } from "../../styles";
import { TipoNegociacaoPedidoDescricao } from "../../../../../../../../enum/TipoNegociacaoPedido";
import { TemposNegociacao } from "../TemposNegociacao";
import { Download, Message, OpenInNew } from "@mui/icons-material";
import { AlternativasNegociacaoPedidoReembolsoTotal } from "../AlternativasNegociacaoPedidoReembolsoTotal";
import { INegociacaoPedido } from "../../../../../../../../interface/INegociacaoPedido";
import { IPedido } from "../../../../../../../../interface/IPedido";
import { useMoeda } from "../../../../../../../../../core/hooks/useMoeda";
import { useDownload } from "../../../../../../../../../core/hooks/useDownload";
import { useState } from "react";
import { useSnackbar } from "../../../../../../../../../core/contexts/SnackbarContext";
import { validarMoedaReal } from "../../../../../../../../../core/utils/validarMoedaReal";
import { IAlternativaNegociacaoPedido } from "../../../../../../../../interface/IAlternativaNegociacaoPedido";

interface NegociacaoReembolsoTotalProps {
    negociacao: INegociacaoPedido;
    pedido: IPedido;
    aceitarNegociacao: (pedidoUuid: string) => Promise<void>;
    recusarNegociacao: (pedidoUuid: string, motivo: string) => Promise<void>;
    efetuarContrapropostaNegociacao: (pedidoUuid: string, valor: number) => Promise<void>;
    loading: boolean;
}

export const NegociacaoReembolsoTotal = ({ negociacao, pedido, aceitarNegociacao, recusarNegociacao, efetuarContrapropostaNegociacao, loading }: NegociacaoReembolsoTotalProps) => {
    const { number } = useMoeda();
    const { downloadPorUrl } = useDownload();

    type OpcaoNegociacao = "ACEITAR" | "RECUSAR" | "OUTRO_VALOR" | undefined;
    const [motivo, setMotivo] = useState<string>();
    const handleMotivo = (motivo: string) => {
        setMotivo(motivo);
    }
    const [opcao, setOpcao] = useState<OpcaoNegociacao>();
    const handleOpcao = (novaOpcao: string) => {
        setOpcao(novaOpcao as OpcaoNegociacao);
    }

    const [valor, setValor] = useState<string>('');
    const handleValor = (valor: string) => {
        setValor(valor);
    }

    const notificar = useSnackbar();

    const handleEnviar = () => {
        if(opcao == "ACEITAR") {
            aceitarNegociacao(pedido.uuid);
        }

        if(opcao == "RECUSAR") {
            if(!motivo || motivo?.length == 0) {
                return notificar("O motivo da rejeição é obrigatório", { severity: "error" })
            }
            recusarNegociacao(pedido.uuid, motivo);
        }

        const alternativa = negociacao.alternativas[0];

        if(opcao == "OUTRO_VALOR" && alternativa) {
            const valorValidacao = validarMoedaReal(valor, 0, alternativa.metadados?.valorMaximo, true);
            if(valorValidacao) {
                return notificar(valorValidacao, { severity: "error" });
            }

            efetuarContrapropostaNegociacao(pedido.uuid, number(valor))
        }
    }

    return (
        <NegociacaoCard variant="outlined">
            <NegociacaoCardHeader>
                <Typography variant="h6">
                    {TipoNegociacaoPedidoDescricao[negociacao.tipo]}
                </Typography>
            </NegociacaoCardHeader>
            {negociacao && (
                <TemposNegociacao
                    criadoAs={negociacao.criadoAs}
                    expiradoAs={negociacao.expiradoAs}
                />
            )}
            {negociacao?.metadados?.evidencias?.length && (
                <Card variant="outlined" sx={{ width: "100%", p: 2, overflowX: "auto" }}>
                    <Typography fontWeight={"bold"}>
                        Evidências
                    </Typography>
                    <TableContainer sx={{ width: "100%", overflowX: "auto" }}>
                        <Table>
                            <TableBody>
                                {negociacao?.metadados?.evidencias?.map(anexo => (
                                    <TableRow
                                        key={anexo.uuid}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell align="left">{anexo.descricao}</TableCell>
                                        <TableCell align="left">
                                            <Download
                                                sx={{ cursor: 'pointer', color: 'primary.main' }}
                                                onClick={() => downloadPorUrl(anexo.url, anexo.nome)}
                                            />
                                        </TableCell>
                                        <TableCell align="left">
                                            <OpenInNew
                                                sx={{ cursor: 'pointer', color: 'primary.main' }}
                                                onClick={() => window.open(anexo.url, '_blank')}
                                            />
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Card>
            )}
            {negociacao.mensagem && (
                <MensagemNegociacaoCard variant="outlined">
                    <Typography fontWeight={"bold"}>
                        Mensagem do consumidor
                    </Typography>
                    <Typography variant="subtitle2">
                        {negociacao.mensagem}
                    </Typography>
                </MensagemNegociacaoCard>
            )}
            <AlternativasNegociacaoCard>
                <Typography variant="subtitle1">Escolha uma das alternativas para responder:</Typography>
                {negociacao?.alternativas?.map((alternativa, index) => {
                    return <AlternativasNegociacaoPedidoReembolsoTotal 
                        key={index} 
                        pedido={pedido} 
                        alternativa={alternativa} 
                        negociacao={negociacao} 
                        handleOpcao={handleOpcao}
                        opcao={opcao}
                        handleMotivo={handleMotivo}
                        motivo={motivo}
                        valor={valor}
                        handleValor={handleValor}
                    />
                })}
            </AlternativasNegociacaoCard>
            <Button onClick={handleEnviar} variant={loading ? "outlined" : "contained"} fullWidth size="large" disabled={loading || !opcao}>
                {loading ? <CircularProgress color="primary" size={25} /> : "Enviar"}
            </Button>
        </NegociacaoCard>
    );
}