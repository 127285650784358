import { Box, FormControlLabel, Radio, Typography } from "@mui/material";
import { CardContainer } from "./styles";
import React, { ReactNode } from "react";

interface RadioCardProps {
    labelValue: any;
    labelTitle: string | ReactNode;
    labelDescription?: string | ReactNode;
    selected?: boolean;
    onClick?: () => any;
    tools?: React.ReactNode;
    toolsPosition?: 'TOP_RIGHT' | 'BOTTOM_LEFT'
    fullWidth?: boolean;
}

export const RadioCard = ({ labelTitle, labelValue, labelDescription, selected, onClick, tools, toolsPosition, fullWidth }: RadioCardProps) => {

    if(tools && !toolsPosition) {
        toolsPosition = 'TOP_RIGHT';
    }

    return (
        <CardContainer variant="outlined" onClick={onClick} selected={selected} fullWidth={fullWidth}>
            <Box sx={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                <FormControlLabel value={labelValue} control={<Radio checked={selected} />} label={labelTitle}/>
                {toolsPosition == 'TOP_RIGHT' && <>{tools}</>}
            </Box>
            {labelDescription && <Typography sx={{ fontSize: "12px", p: "0.5rem" }}>{labelDescription}</Typography>}
            {toolsPosition == 'BOTTOM_LEFT' && <>{tools}</>}
        </CardContainer>
    );
}