import { Autocomplete, Avatar, Box, Button, Divider, Drawer, Grid, Paper, Stack, TextField, Typography } from "@mui/material";
import { BodyDetalhesContainer, DetalhesContainer, HeaderDetalhesContainer } from "../CardPizzaOperacao/styles";
import { useMoeda } from "../../../core/hooks/useMoeda";
import { useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import { ISabor } from "../../interfaces/ISabor";
import { IBorda } from "../../interfaces/IBorda";
import { IProduto } from "../../interfaces/IProduto";
import { useSnackbar } from "../../../core/contexts/SnackbarContext";
import { RealInput } from "../../../core/components/RealInput";
import { IProdutoPedido } from "../../../pedidos/interface/IProdutoPedido";
import { PaperMontagem, PaperResumo } from "./styles";
import { useTamanhoTela } from "../../../core/hooks/useTamanhoTela";
import { IMassa } from "../../interfaces/IMassa";

interface DrawerPizzaOperacaoProps {
    open: boolean;
    toggleDrawer: () => void;
    produto: IProduto;
    onSubmit: (produto: IProdutoPedido) => void;

    sabores?: ISabor[];
    borda?: IBorda;
    massa?: IMassa;
    observacao?: string;
    valorAdicional?: any;
    uuid?: string;
}

export const DrawerPizzaOperacao = ({
    valorAdicional: valorAdicionalEdicao,
    observacao: observacaoEdicao,
    sabores: saboresEdicao,
    borda: bordaEdicao,
    massa: massaEdicao,
    toggleDrawer,
    onSubmit,
    produto,
    open,
    uuid
}: DrawerPizzaOperacaoProps) => {
    const [sabores, setSabores] = useState<ISabor[]>([]);
    const [massa, setMassa] = useState<IMassa | undefined | null>(null);
    const [borda, setBorda] = useState<IBorda | undefined | null>(null);
    const [observacao, setObservacao] = useState<string | undefined>(undefined);
    const [valorAdicional, setValorAdicional] = useState<any>(undefined);
    const { modeloTela } = useTamanhoTela();

    const snackbar = useSnackbar();
    const { real } = useMoeda();

    const handleValorAdicional = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValorAdicional(Number(event.target.value))
    }

    const handleObservacao = (event: React.ChangeEvent<HTMLInputElement>) => {
        setObservacao(event.target.value)
    }

    const handleBordaChange = (event: React.SyntheticEvent<Element, Event>, value: IBorda | null) => {
        setBorda(value);
    }

    const handleMassaChange = (event: React.SyntheticEvent<Element, Event>, value: IBorda | null) => {
        setMassa(value);
    }

    const handleSaboresChange = (event: React.SyntheticEvent<Element, Event>, value: ISabor[]) => {
        if (produto.quantidadeSabores) {
            if (value.length > produto.quantidadeSabores) {
                return snackbar(`Você já selecionou os ${produto.quantidadeSabores} sabores`, { severity: "error" })
            }
        }
        setSabores(value);
    }

    const handleToggleDrawer = () => {
        if (!saboresEdicao) {
            setSabores([]);
        }

        if (!valorAdicionalEdicao) {
            setValorAdicional(undefined);
        }

        if (!observacaoEdicao) {
            setObservacao(undefined)
        }

        if (!bordaEdicao) {
            setBorda(null);
        }

        if (!massaEdicao) {
            setMassa(null);
        }

        toggleDrawer();
    }

    const valorTotalSabor = useMemo(() => {
        // Precificação por preço dividido
        if (produto.configuracao?.modeloPrecificacao === "precoDividido") {
            let total = 0;
            sabores.forEach(sabor => {
                total += sabor.preco;
            });
            return Math.ceil(total / sabores.length);
        }

        // Precificação pelo maior preço
        if (produto.configuracao?.modeloPrecificacao === "maiorPreco") {
            if (sabores.length === 0) {
                return 0;
            }
            const saborMaisCaro = sabores.reduce((maior, atual) => maior.preco > atual.preco ? maior : atual);
            return saborMaisCaro.preco;
        }

        // Precificação mista
        if (produto.configuracao?.modeloPrecificacao === "precoMisto") {
            // Se a quantidade de sabores for maior que 2, usa a lógica do maior preço
            if (sabores.length > 2) {
                const saborMaisCaro = sabores.reduce((maior, atual) => maior.preco > atual.preco ? maior : atual);
                return saborMaisCaro.preco;
            } else {
                // Se a quantidade de sabores for 2 ou menos, usa a lógica de preço dividido
                let total = 0;
                sabores.forEach(sabor => {
                    total += sabor.preco;
                });
                return Math.ceil(total / sabores.length);
            }
        }

        // Retorna 0 como valor padrão se nenhuma das condições anteriores for verdadeira
        return 0;

    }, [sabores, produto.configuracao]);


    const valorTotal = useMemo(() => {
        let total = (valorTotalSabor ?? 0) + (borda?.preco ?? 0) + (massa?.preco ?? 0) + (valorAdicional ?? 0);
        return total;
    }, [sabores, borda, valorAdicional])

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        if (!sabores.length) {
            snackbar("Sabores inválidos", { severity: "error" })
            return;
        }

        const dadosProduto: IProdutoPedido = {
            uuidProdutoReferencia: produto.uuid,
            valorAdicional,
            ...produto,
            observacao,
            sabores,
            massa: massa ?? undefined,
            borda: borda ?? undefined
        }

        if (uuid) {
            dadosProduto.uuid = uuid;
        }

        onSubmit(dadosProduto);
        handleToggleDrawer();
    }

    useEffect(() => {
        if (bordaEdicao) {
            setBorda(bordaEdicao);
        }

        if (massaEdicao) {
            setMassa(massaEdicao);
        }

        if (saboresEdicao) {
            setSabores(saboresEdicao)
        }

        if (observacaoEdicao) {
            setObservacao(observacaoEdicao);
        }

        if (valorAdicionalEdicao) {
            setValorAdicional(valorAdicionalEdicao);
        }
    }, [
        valorAdicionalEdicao,
        observacaoEdicao,
        saboresEdicao,
        bordaEdicao
    ])

    return (
        <Drawer
            anchor="bottom"
            open={open}
            onClose={handleToggleDrawer}
            sx={{ borderRadius: 0 }}
            disableRestoreFocus
        >
            <form onSubmit={handleSubmit}>
                <DetalhesContainer>
                    <HeaderDetalhesContainer>
                        {produto.imagens?.length ? (
                            <Avatar
                                alt="Pizza"
                                src={`${produto.imagens[0].url}`}
                                sx={{ width: 90, height: 90 }}
                            />
                        ) : (
                            <Avatar
                                alt="Pizza"
                                src={`${process.env.PUBLIC_URL}/images/produtos/pizza.png`}
                                sx={{ width: 90, height: 90, objectFit: "contain" }}
                            />
                        )}
                        <div>
                            <Typography gutterBottom variant="h5" fontWeight={"bold"} component="div">
                                {produto.descricao}
                            </Typography>
                            <Typography gutterBottom variant="h6" fontWeight={"bold"} component="div">
                                {real(valorTotal)}
                            </Typography>
                        </div>
                    </HeaderDetalhesContainer>
                    <BodyDetalhesContainer>
                        <Grid
                            spacing={1}
                            container
                            height={"100%"}
                        >
                            <Grid item xs={12} sm={6}>
                                <PaperMontagem variant="outlined">
                                    <Typography gutterBottom variant="h6" fontWeight={"bold"} component="div">
                                        Montagem
                                    </Typography>
                                    <Box
                                        sx={{
                                            width: "100%",
                                            padding: "1rem",
                                            display: "flex",
                                            flexDirection: "column",
                                            justifyContent: "center",
                                            alignItems: "center"
                                        }}
                                    >
                                        <Stack width={"100%"} flexDirection={"row"} justifyContent={"space-between"}>
                                            <Typography gutterBottom variant="h6" component="div">
                                                Sabores
                                            </Typography>
                                            <Typography gutterBottom variant="h6" fontWeight={"bold"} component="div">
                                                {sabores && sabores.length && valorTotalSabor ? real(valorTotalSabor) : "R$0,00"}
                                            </Typography>
                                        </Stack>
                                        {produto.sabores && (
                                            <Autocomplete
                                                multiple
                                                value={sabores}
                                                noOptionsText="Nenhum sabor encontrado"
                                                onChange={handleSaboresChange}
                                                options={produto.sabores}
                                                getOptionLabel={(sabor) => sabor.descricao}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Sabores"
                                                        placeholder="Sabores"
                                                        autoFocus
                                                    />
                                                )}
                                                sx={{ width: '100%', marginTop: "0.5rem" }}
                                                isOptionEqualToValue={(option, value) => false}
                                            />
                                        )}
                                    </Box>

                                    <Divider />

                                    <Box
                                        sx={{
                                            width: "100%",
                                            padding: "1rem",
                                            display: "flex",
                                            flexDirection: "column",
                                            justifyContent: "center",
                                            alignItems: "center"
                                        }}
                                    >
                                        <Stack width={"100%"} flexDirection={"row"} justifyContent={"space-between"}>
                                            <Typography gutterBottom variant="h6" component="div">
                                                Borda
                                            </Typography>
                                            <Typography gutterBottom variant="h6" fontWeight={"bold"} component="div">
                                                {borda ? real(borda.preco) : "R$ 0,00"}
                                            </Typography>
                                        </Stack>
                                        {produto.bordas && (
                                            <Autocomplete
                                                value={borda}
                                                onChange={handleBordaChange}
                                                noOptionsText="Nenhuma borda encontrada"
                                                options={produto.bordas}
                                                getOptionLabel={(borda) => borda.descricao}
                                                renderInput={(params) => (
                                                    <TextField {...params} label="Bordas" placeholder="Bordas" />
                                                )}
                                                sx={{ width: '100%', marginTop: "0.5rem" }}
                                            />
                                        )}
                                    </Box>

                                    <Divider />

                                    <Box
                                        sx={{
                                            width: "100%",
                                            padding: "1rem",
                                            display: "flex",
                                            flexDirection: "column",
                                            justifyContent: "center",
                                            alignItems: "center"
                                        }}
                                    >
                                        <Stack width={"100%"} flexDirection={"row"} justifyContent={"space-between"}>
                                            <Typography gutterBottom variant="h6" component="div">
                                                Massa
                                            </Typography>
                                            <Typography gutterBottom variant="h6" fontWeight={"bold"} component="div">
                                                {massa ? real(massa.preco) : "R$ 0,00"}
                                            </Typography>
                                        </Stack>
                                        {produto.massas && (
                                            <Autocomplete
                                                value={massa}
                                                onChange={handleMassaChange}
                                                noOptionsText="Nenhuma massa encontrada"
                                                options={produto.massas}
                                                getOptionLabel={(massa) => massa.descricao}
                                                renderInput={(params) => (
                                                    <TextField {...params} label="Massas" placeholder="Massas" />
                                                )}
                                                sx={{ width: '100%', marginTop: "0.5rem" }}
                                            />
                                        )}
                                    </Box>
                                    <Divider />
                                    <Box
                                        sx={{
                                            width: "100%",
                                            padding: "1rem",
                                            display: "flex",
                                            flexDirection: "column",
                                            justifyContent: "center",
                                            alignItems: "center"
                                        }}
                                    >
                                        <Stack width={"100%"} flexDirection={"row"} justifyContent={"space-between"}>
                                            <Typography gutterBottom variant="h6" component="div">
                                                Observação
                                            </Typography>
                                            <Typography gutterBottom variant="h6" fontWeight={"bold"} component="div">
                                                {valorAdicional ? real(valorAdicional) : "R$ 0,00"}
                                            </Typography>
                                        </Stack>
                                        <Box sx={{ width: "100%", justifyContent: "center", display: "flex" }}>
                                            <TextField
                                                sx={{ width: '65%' }}
                                                label="Observação"
                                                placeholder="Observação"
                                                onChange={handleObservacao}
                                                value={observacao}
                                            />
                                            <TextField
                                                sx={{ width: '35%', marginLeft: "0.5rem" }}
                                                label="Valor adicional"
                                                placeholder="Valor adicional"
                                                onChange={handleValorAdicional}
                                                value={valorAdicional}
                                                InputProps={{
                                                    inputComponent: RealInput as any,
                                                }}
                                            />
                                        </Box>
                                    </Box>
                                </PaperMontagem>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                {modeloTela == "desktop" ? (
                                    <PaperResumo variant="outlined">
                                        <Typography gutterBottom variant="h6" fontWeight={"bold"} component="div">
                                            Resumo
                                        </Typography>
                                        <Stack
                                            width={"100%"}
                                            flexDirection={"column"}
                                            justifyContent={"flex-start"}
                                            alignItems={"center"}
                                            height={"100%"}
                                        >
                                            <Paper variant='outlined' sx={{ padding: "1rem", width: "100%" }}>
                                                <Typography gutterBottom variant="h6" fontWeight={"bold"} component="div">
                                                    Borda
                                                </Typography>
                                                {borda && (
                                                    <>{borda.descricao}</>
                                                )}
                                            </Paper>

                                            <Paper variant='outlined' sx={{ padding: "1rem", width: "100%", marginTop: "1rem" }}>
                                                <Typography gutterBottom variant="h6" fontWeight={"bold"} component="div">
                                                    Sabores
                                                </Typography>
                                                <Stack sx={{ height: "100%" }} flexDirection={"column"}>
                                                    {sabores && sabores.length >= 1 && sabores.map((sabor, index) => {
                                                        if (sabor.descricao) {
                                                            return <div key={index}>{sabor.descricao}</div>
                                                        }
                                                    })}
                                                </Stack>
                                            </Paper>
                                            <Paper variant='outlined' sx={{ padding: "1rem", width: "100%" }}>
                                                <Typography gutterBottom variant="h6" fontWeight={"bold"} component="div">
                                                    Massa
                                                </Typography>
                                                {massa && (
                                                    <>{massa.descricao}</>
                                                )}
                                            </Paper>
                                            <Paper variant='outlined' sx={{ padding: "1rem", width: "100%", marginTop: "1rem" }}>
                                                <Typography gutterBottom variant="h6" fontWeight={"bold"} component="div">
                                                    Observação
                                                </Typography>
                                                <Stack sx={{ height: "100%" }} flexDirection={"column"}>
                                                    {observacao && <div>{observacao}</div>}
                                                </Stack>
                                            </Paper>
                                            <Button type='submit' variant='contained' sx={{ marginTop: "2rem", width: "100%", height: "90px" }}>
                                                CONCLUIR
                                            </Button>
                                        </Stack>
                                    </PaperResumo>
                                ) : (
                                    <Button type='submit' variant='contained' sx={{ marginTop: "2rem", width: "100%", height: "90px" }}>
                                        CONCLUIR
                                    </Button>
                                )}

                            </Grid>
                        </Grid>
                    </BodyDetalhesContainer>
                </DetalhesContainer>
            </form>
        </Drawer>
    );
}