import { useState, useEffect } from "react";
import { Alert, AlertTitle, Button, CircularProgress, Typography } from "@mui/material";
import { Tools, ToolsContainer, ToolsPaper, ToolsTitle, } from "./styles";
import { IPedido } from "../../../../interface/IPedido";
import { StatusPedido, StatusPedidoDescricao } from "../../../../enum/StatusPedido";
import { usePedidos } from "../../../../hooks/usePedidos";
import { TipoPedido } from "../../../../enum/TipoPedido";
import { ToolsAguardandoConfirmacao } from "./components/ToolsAguardandoConfirmacao";
import { ToolsEmPreparoDelivery } from "./components/ToolsEmPreparoDelivery";
import { ToolsEmPreparoRetirada } from "./components/ToolsEmPreparoRetirada";
import { ToolsEmTransporte } from "./components/ToolsEmTransporte";
import { ToolsProntoRetirada } from "./components/ToolsProntoRetirada";
import { ModalCancelarPedido } from "./components/ModalCancelarPedido";
import { NegociacaoPedido } from "./components/NegociacaoPedido";

interface FerramentasPedidosProps {
    pedidosSelecionados: IPedido[];
    desselecionarPedido: (pedido: IPedido) => void;
    atualizarListagem: () => void;
}

export const FerramentasPedidos = ({ pedidosSelecionados, atualizarListagem, desselecionarPedido }: FerramentasPedidosProps) => {
    const [alertaTipo, setAlertaTipo] = useState(false);
    const [alertaMarketplace, setAlertaMarketplace] = useState(false);
    const [alertaStatus, setAlertaStatus] = useState(false);
    
    const [cancelarOpen, setCancelarOpen] = useState(false);

    const abrirCancelamento = () => {
        setCancelarOpen(true);
    }

    const fecharCancelamento = () => {
        setCancelarOpen(false);
    }

    const { atualizarStatus, loading } = usePedidos();
    const handleAtualizarStatus = async (status: StatusPedidoDescricao) => {
        for (const pedido of pedidosSelecionados) {
            await atualizarStatus({
                pedidoUuid: pedido.uuid,
                status
            });
        }
        
        atualizarListagem();
    }

    useEffect(() => {
        if (pedidosSelecionados.length > 0) {
            const tipos = pedidosSelecionados
                .map(pedido => pedido.tipo)
                .filter((tipo, index, self) => self.indexOf(tipo) === index);

            const marketplaces = pedidosSelecionados
                .map(pedido => pedido.marketplace.tipo)
                .filter((marketplace, index, self) => self.indexOf(marketplace) === index);

            const status = pedidosSelecionados
                .map(pedido => pedido.status)
                .filter((status, index, self) => self.indexOf(status) === index);

            if (tipos.length > 1) {
                setAlertaTipo(true);
                setAlertaMarketplace(false);
                setAlertaStatus(false);
            } else if (marketplaces.length > 1) {
                setAlertaTipo(false);
                setAlertaMarketplace(true);
                setAlertaStatus(false);
            } else if (status.length > 1) {
                setAlertaTipo(false);
                setAlertaMarketplace(false);
                setAlertaStatus(true);
            } else {
                setAlertaTipo(false);
                setAlertaMarketplace(false);
                setAlertaStatus(false);
            }
        } else {
            setAlertaTipo(false);
            setAlertaMarketplace(false);
            setAlertaStatus(false);
        }
    }, [pedidosSelecionados]);

    if (!pedidosSelecionados.length) {
        return <></>;
    }

    if (alertaTipo || alertaMarketplace || alertaStatus) {
        return (
            <FerramentasAlertas
                alertaTipo={alertaTipo}
                alertaMarketplace={alertaMarketplace}
                alertaStatus={alertaStatus}
            />
        );
    }

    return (
        <ToolsPaper elevation={5}>
            {pedidosSelecionados[0]?.status == StatusPedido["Aguardando confirmação"] && (
                <ToolsAguardandoConfirmacao 
                    quantidadePedidosSelecionados={pedidosSelecionados?.length}
                    abrirCancelamento={abrirCancelamento}
                    marketplace={pedidosSelecionados[0]?.marketplace}
                    atualizarStatus={handleAtualizarStatus}
                    loading={loading}
                />
            )}
            {pedidosSelecionados[0]?.status == StatusPedido["Em preparo"] && pedidosSelecionados[0]?.tipo == TipoPedido.delivery && (
                <ToolsEmPreparoDelivery 
                    quantidadePedidosSelecionados={pedidosSelecionados?.length}
                    abrirCancelamento={abrirCancelamento}
                    marketplace={pedidosSelecionados[0]?.marketplace}
                    atualizarStatus={handleAtualizarStatus}
                    loading={loading}
                />
            )}
            {pedidosSelecionados[0]?.status == StatusPedido["Em preparo"] && pedidosSelecionados[0]?.tipo == TipoPedido.retirada && (
                <ToolsEmPreparoRetirada 
                    quantidadePedidosSelecionados={pedidosSelecionados?.length}
                    abrirCancelamento={abrirCancelamento}
                    marketplace={pedidosSelecionados[0]?.marketplace}
                    atualizarStatus={handleAtualizarStatus}
                    loading={loading}
                />
            )}
            {pedidosSelecionados[0]?.status == StatusPedido["Em transporte"] && pedidosSelecionados[0]?.tipo == TipoPedido.delivery && (
                <ToolsEmTransporte 
                    quantidadePedidosSelecionados={pedidosSelecionados?.length}
                    abrirCancelamento={abrirCancelamento}
                    marketplace={pedidosSelecionados[0]?.marketplace}
                    atualizarStatus={handleAtualizarStatus}
                    loading={loading}
                />
            )}
            {pedidosSelecionados[0]?.status == StatusPedido["Pronto para retirada"] && pedidosSelecionados[0]?.tipo == TipoPedido.retirada && (
                <ToolsProntoRetirada 
                    quantidadePedidosSelecionados={pedidosSelecionados?.length}
                    abrirCancelamento={abrirCancelamento}
                    marketplace={pedidosSelecionados[0]?.marketplace}
                    atualizarStatus={handleAtualizarStatus}
                    loading={loading}
                />
            )}
            <ModalCancelarPedido 
                open={cancelarOpen}
                fechar={fecharCancelamento}
                atualizarStatus={async (props: any) =>  {
                    await atualizarStatus(props);
                    atualizarListagem();
                }}
                loading={loading}
                pedido={pedidosSelecionados[0]}
            />
            <NegociacaoPedido 
                atualizarListagem={atualizarListagem}
                pedidosSelecionados={pedidosSelecionados}
                desselecionarPedido={desselecionarPedido}
            />
        </ToolsPaper>
    );
};

interface FerramentasAlertasProps {
    alertaTipo: boolean;
    alertaMarketplace: boolean;
    alertaStatus: boolean;
}

export const FerramentasAlertas = ({ alertaTipo, alertaMarketplace, alertaStatus }: FerramentasAlertasProps) => {
    return (
        <ToolsPaper elevation={5}>
            {alertaTipo && (
                <Alert severity="warning" sx={{ width: "calc(100% - 240px)" }}>
                    <AlertTitle>
                        Ação não permitida
                    </AlertTitle>
                    Existem diferentes contextos de pedidos selecionados.<br />
                    Ex: Retirada, delivery, etc.
                </Alert>
            )}
            {!alertaTipo && alertaMarketplace && (
                <Alert severity="warning" sx={{ width: "calc(100% - 240px)" }}>
                    <AlertTitle>
                        Ação não permitida
                    </AlertTitle>
                    Existem diferentes marketplaces selecionados.<br />
                    Ex: PDV, iFood, etc.
                </Alert>
            )}
            {!alertaTipo && !alertaMarketplace && alertaStatus && (
                <Alert severity="warning" sx={{ width: "calc(100% - 240px)" }}>
                    <AlertTitle>
                        Ação não permitida
                    </AlertTitle>
                    Existem diferentes status selecionados.<br />
                    Ex: Em preparo, Em transporte, etc.
                </Alert>
            )}
        </ToolsPaper>
    );
};
