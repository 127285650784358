import { Add, Delete, Edit } from "@mui/icons-material";
import { Box, Button, Dialog, DialogContent, FormControl, FormHelperText, IconButton, InputAdornment, InputLabel, OutlinedInput, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { v4 } from "uuid";
import { useMoeda } from "../../../../../core/hooks/useMoeda";
import { validarMoedaReal } from "../../../../../core/utils/validarMoedaReal";
import { IITemComplemento } from "../../../../interfaces/IITemComplemento";
import { IInsumo } from "../../../../interfaces/IInsumo";
import { FichaTecnica } from "../../../FichaTecnica";
import { ButtonsContainer, ItemCard, ItemImage, ItensPaper } from "./styles";
import { TipoControleEstoque } from "../../../../enum/TipoControleEstoque";
import { useCadastrarEditarProdutoContext } from "../../../../contexts/CadastrarEditarProdutoContext";
import { IImagemProduto } from "../../../../interfaces/IImagemProduto";
import { ImagemContainer } from "../../../ConfiguracaoPreparadoContainer/styles";
import { CardUploadImagem } from "../../../../../core/components/CardUploadImagem";
import { MIMETypes } from "../../../../../core/enum/MIMETypes";
import { useSnackbar } from "../../../../../core/contexts/SnackbarContext";
import { useLetras } from "../../../../../core/hooks/useLetras";

interface ItensComplementosContainerProps {
    itens?: IITemComplemento[];
    salvar: (item: IITemComplemento) => void;
    excluir: (uuid: string) => void;
}

export const ItensComplementosContainer = ({ salvar, itens, excluir }: ItensComplementosContainerProps) => {
    const [fichaTecnica, setFichaTecnica] = useState<IInsumo[]>([]);
    const [descricao, setDescricao] = useState<string | undefined>('');
    const [imagem, setImagem] = useState<undefined | File | IImagemProduto>(undefined);
    const [detalhes, setDetalhes] = useState<string | undefined>('');
    const [preco, setPreco] = useState<string | undefined>('');
    const [erroDescricao, setErroDescricao] = useState<undefined | string>(undefined);
    const [erroDetalhes, setErroDetalhes] = useState<undefined | string>(undefined);
    const [erroPreco, setErroPreco] = useState<undefined | string>(undefined);
    const [itemEdicao, setItemEdicao] = useState<undefined | IITemComplemento>(undefined);
    const { controleEstoque } = useCadastrarEditarProdutoContext();
    const [formularioAberto, setFormularioAberto] = useState<boolean>(false);
    const abrirFormulario = () => setFormularioAberto(true);
    const fecharFormulario = () => {
        setDescricao('');
        setPreco('');
        setDetalhes('');
        setFormularioAberto(false);
        setErroDescricao(undefined);
        setErroPreco(undefined);
        setErroDetalhes(undefined);
        setItemEdicao(undefined);
        setImagem(undefined);
    }

    const { number, real } = useMoeda();

    const editar = (item: IITemComplemento) => {
        setItemEdicao(item);
        abrirFormulario();
    }

    const handleDescricao = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setDescricao(e.target.value)

        if (!e.target.value) {
            setErroDescricao("Descrição inválida");
        } else {
            if (e.target.value.length >= 100) {
                setErroDescricao("O limite de caracteres é 100");
            } else {
                setErroDescricao(undefined);
            }
        }
    }

    const handleDetalhes = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setDetalhes(e.target.value)

        if (e.target.value.length >= 240) {
            setErroDetalhes("O limite de caracteres é 250");
        }
    }

    const handlePreco = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setPreco(e.target.value);

        const validacaoPreco = validarMoedaReal(e.target.value, 0.00, undefined, true);
        if (e.target.value?.length) {
            setErroPreco(validacaoPreco);
        } else {
            setErroPreco(undefined);
        }
    }

    const handleSalvar = () => {
        let erro = false;

        if (!descricao) {
            setErroDescricao("A descrição é obrigatória");
            erro = true;
        }

        const validacaoPreco = validarMoedaReal(preco, 0.00, undefined, true);
        if (!preco || validacaoPreco) {
            setErroPreco(validacaoPreco || "O preço é obrigatório");
            erro = true;
        }

        if (!erro && descricao) {
            const imagens: IImagemProduto[] = [];
            if (imagem) {
                imagens.push(imagem as IImagemProduto);
            }
            salvar({
                descricao,
                fichaTecnica,
                preco: number(preco),
                detalhes,
                uuid: itemEdicao?.uuid ?? v4(),
                imagens
            });

            fecharFormulario();
        }
    }

    const adicionarInsumo = (insumo: IInsumo) => {
        setFichaTecnica(ficha => [...ficha, insumo]);
    }

    const excluirInsumo = (uuid: string) => {
        setFichaTecnica(ficha => ficha.filter(insumo => insumo.uuid !== uuid));
    }

    const mimetypesValidos = [
        MIMETypes["image/jpeg"],
        MIMETypes["image/jpg"],
        MIMETypes["image/png"]
    ] as string[];

    const snackbar = useSnackbar();

    const handleUploadImagem = (arquivo: File) => {
        if (!mimetypesValidos.includes(arquivo.type)) {
            return snackbar(`Arquivo inválido, tipos aceitos: (PNG, JPEG)`, { severity: "error" })
        }

        setImagem(arquivo);
    }

    const handleExcluirImagem = () => {
        setImagem(undefined);
    }

    useEffect(() => {
        if (itemEdicao) {
            setDescricao(itemEdicao.descricao);
            setDetalhes(itemEdicao.detalhes ?? '');
            setPreco(String(itemEdicao.preco));
            setFichaTecnica(itemEdicao.fichaTecnica ?? []);
            if (itemEdicao.imagens?.length) {
                setImagem(itemEdicao.imagens[0]);
            }
        }
    }, [itemEdicao])

    const { titulo } = useLetras();

    return (
        <ItensPaper variant="outlined">
            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "1rem" }}>
                <Typography
                    variant="h6"
                >
                    Itens
                </Typography>
                <Button
                    variant="contained"
                    startIcon={<Add />}
                    onClick={abrirFormulario}
                >
                    Item
                </Button>
            </Box>
            {itens?.map(item => {
                return (
                    <ItemCard variant="outlined">
                        <Box>
                            <ItemImage>
                                <img
                                    src={item?.imagens?.length
                                        ? (item?.imagens[0] instanceof File ? URL.createObjectURL(item?.imagens[0]) : item?.imagens[0].url)
                                        : `${process.env.PUBLIC_URL}/images/produtos/generico.png`
                                    }
                                    alt={item.descricao}
                                />
                            </ItemImage>
                        </Box>
                        <Box sx={{
                            width: "100%",
                            display: 'flex',
                            justifyContent: 'center',
                            flexDirection: 'column'
                        }}>
                            <Typography>
                                {item.descricao}
                            </Typography>
                            {item.detalhes && (
                                <Typography fontSize={12} color={'GrayText'}>
                                    {titulo(item.detalhes, 20)}
                                </Typography>
                            )}
                            <Typography fontWeight={"bold"}>
                                {real(item.preco)}
                            </Typography>
                        </Box>
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'center',
                        }}>
                            <IconButton onClick={() => editar(item)}>
                                <Edit color="primary" />
                            </IconButton>
                            <IconButton onClick={() => excluir(item.uuid)}>
                                <Delete color="error" />
                            </IconButton>
                        </Box>
                    </ItemCard>
                );
            })}
            <Dialog open={formularioAberto} onClose={fecharFormulario} maxWidth="md">
                <DialogContent sx={{ display: "flex", flexDirection: "column", minWidth: "500px", maxHeight: "80vh", overflowY: "auto" }}>
                    <Typography
                        variant="h6"
                    >
                        {itemEdicao?.uuid ? "Editar" : "Cadastrar"} item
                    </Typography>
                    <FormControl fullWidth sx={{ mt: 3 }}>
                        <TextField
                            type="text"
                            placeholder="Ex: Bacon, Carne extra, etc..."
                            value={descricao}
                            onChange={handleDescricao}
                            label="Descrição"
                            error={erroDescricao ? true : false}
                            helperText={<>{erroDescricao}</>}
                        />
                    </FormControl>
                    <FormControl fullWidth sx={{ mt: 3 }}>
                        <TextField
                            type="text"
                            placeholder="Detalhes"
                            value={detalhes}
                            onChange={handleDetalhes}
                            label="Detalhes"
                            error={erroDetalhes ? true : false}
                            helperText={<>{erroDetalhes}</>}
                            multiline
                            rows={3}
                        />
                    </FormControl>
                    <FormControl fullWidth sx={{ mt: 3 }}>
                        <InputLabel htmlFor="preco-item-complemento" error={erroPreco ? true : false}>Preço</InputLabel>
                        <OutlinedInput
                            id="preco-item-complemento"
                            startAdornment={<InputAdornment position="start">R$</InputAdornment>}
                            label="Preço"
                            onChange={handlePreco}
                            value={preco}
                            error={erroPreco ? true : false}
                        />
                        {erroPreco && (<FormHelperText error={true}>{erroPreco}</FormHelperText>)}
                    </FormControl>
                    {controleEstoque == TipoControleEstoque.completo && <FichaTecnica adicionarInsumo={adicionarInsumo} fichaTecnica={fichaTecnica} excluirInsumo={excluirInsumo} />}
                    <ImagemContainer>
                        <CardUploadImagem
                            titulo="Imagem"
                            descricao={
                                imagem
                                    ? (imagem instanceof File ? imagem?.name : (imagem?.nomeOriginal ?? imagem?.nome))
                                    : "Faça o upload"
                            }
                            url={imagem instanceof File ? URL.createObjectURL(imagem) : imagem?.url}
                            excluir={handleExcluirImagem}
                            upload={handleUploadImagem}
                        />
                    </ImagemContainer>
                    <ButtonsContainer>
                        <Button
                            variant="outlined"
                            onClick={fecharFormulario}
                        >
                            Cancelar
                        </Button>
                        <Button
                            variant="contained"
                            onClick={handleSalvar}
                        >
                            Salvar
                        </Button>
                    </ButtonsContainer>
                </DialogContent>
            </Dialog>
        </ItensPaper>
    );
}