import { Breadcrumbs, Grid, Typography, Link, Paper, Box, Chip, Tooltip, Button } from "@mui/material"
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { IMarketplace } from "../../../../interfaces/IMarketplace";
import { useSnackbar } from "../../../../../core/contexts/SnackbarContext";
import { useMarketplaces } from "../../../../hooks/useMarketplaces";
import { useFetchMarketplacesVinculo } from "../../../../hooks/useFetchMarketplacesVinculo";
import { useMoeda } from "../../../../../core/hooks/useMoeda";
import { useAutenticacaoContext } from "../../../../../core/contexts/AutenticacaoContext";
import { DadoContainer, PageContainer } from "../../styles";
import { StatusMarketplacePaper } from "../../components/StatusMarketplacePaper";
import { QRCodeMarketplacePaper } from "../../components/QRCodeMarketplacePaper";
import { TipoMarketplaceCor, TipoMarketplaceDescricao, TipoMarketplaceTitulo } from "../../../../enum/TipoMarketplace";
import { PersonalizacaoMarketplacePaper } from "../../components/PersonalizacaoPaper";
import { MetodosPagamentoMarketplacePaper } from "../../components/MetodosPagamentoMarketplacePaper";
import { HorariosMarketplacePaper } from "../../components/HorariosMarketplacePaper";
import { EnderecoMarketplacePaper } from "../../components/EnderecoMarketplacePaper";
import { RegioesEntregaMarketplacePaper } from "../../components/RegioesEntregaMarketplacePaper";
import { Edit } from "@mui/icons-material";

interface CardapioOnlineContainerProps {
    marketplace?: IMarketplace
    handleRefresh: () => void, 
    loadingFetch: boolean
}

export const CardapioOnlineContainer = ({ marketplace, handleRefresh, loadingFetch }: CardapioOnlineContainerProps) => {
    const navigate = useNavigate();
    const { real } = useMoeda();

    return (
        <PageContainer>
            <Breadcrumbs aria-label="breadcrumb">
                <Link color="primary" href="/marketplace">
                    Marketplaces
                </Link>
                {marketplace && (
                    <Typography color="text.primary">{marketplace.nome}</Typography>
                )}
            </Breadcrumbs>
            <Grid container spacing={2} sx={{ p: 2, mt: 2 }}>
                {!marketplace ? (
                    <div>Carregando...</div>
                ) : (
                    <>
                        <Grid item xs={12} md={6}>
                            <StatusMarketplacePaper marketplace={marketplace} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <QRCodeMarketplacePaper marketplace={marketplace} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Paper sx={{ width: "100%", p: 2, minHeight: "180px", height: "100%" }}>
                                <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                                    <Typography variant="h6">Dados</Typography>
                                    <Button onClick={() => navigate(`/marketplace/editar/${marketplace.uuid}`)} endIcon={<Edit />}>Editar</Button>
                                </Box>
                                <DadoContainer>
                                    <Typography>Nome público</Typography>
                                    <Typography>{marketplace.configuracao?.nomePublico}</Typography>
                                </DadoContainer>
                                <DadoContainer>
                                    <Typography>Nome</Typography>
                                    <Typography>{marketplace.nome}</Typography>
                                </DadoContainer>
                                <DadoContainer>
                                    <Typography>Telefone</Typography>
                                    <Typography>{marketplace.configuracao?.telefone ?? "-"}</Typography>
                                </DadoContainer>
                                <DadoContainer>
                                    <Typography>Valor mínimo</Typography>
                                    <Typography>{real(marketplace.configuracao?.valorMinimo) ?? "-"}</Typography>
                                </DadoContainer>
                                <DadoContainer>
                                    <Typography>Tipo</Typography>
                                    <Tooltip title={TipoMarketplaceDescricao[marketplace.tipo]}>
                                        <Chip
                                            label={TipoMarketplaceTitulo[marketplace.tipo]}
                                            style={{ background: TipoMarketplaceCor[marketplace.tipo], color: "white" }}
                                        />
                                    </Tooltip>
                                </DadoContainer>
                                <DadoContainer>
                                    <Typography>{marketplace.descricao}</Typography>
                                </DadoContainer>
                            </Paper>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <PersonalizacaoMarketplacePaper marketplace={marketplace} />
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <MetodosPagamentoMarketplacePaper refresh={handleRefresh} marketplace={marketplace} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <HorariosMarketplacePaper refresh={handleRefresh} marketplace={marketplace} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <EnderecoMarketplacePaper marketplace={marketplace} />
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <RegioesEntregaMarketplacePaper isLoading={loadingFetch} refresh={handleRefresh} marketplace={marketplace} />
                        </Grid>
                    </>
                )}
            </Grid>
        </PageContainer>
    )
}