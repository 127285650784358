import styled from "@emotion/styled";
import { Box, Card } from "@mui/material";

export const DrawerContainer = styled(Box)`
    min-width: 50vw;
    height: 100%;
    padding: 4.5rem 2rem;
`

export const DrawerHeader = styled(Box)`
    display: flex;
    justify-content: space-between;
`

export const DrawerContent = styled(Box)`
    display: flex;
    flex-direction: column;
    gap: 2rem;
    margin-top: 1rem;
    overflow-y: auto;
`

export const NegociacaoCard = styled(Card)`
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
`

export const NegociacaoCardHeader = styled(Box)`
    display: flex;
    width: 100%;
    flex-direction: column;
`

export const CardContainer = styled(Box)`
    display: flex;
    width: 100%;
    justify-content: space-between;
    gap: 1rem;
`

export const TemposNegociacaoCard = styled(Card)`
    width: 100%;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
`

export const MensagemNegociacaoCard = styled(Card)`
    width: 100%;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
`

export const AlternativasNegociacaoCard = styled(Box)`
    width: 100%;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
`

export const ItensContainerCard = styled(Card)`
    width: 100%;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
`

export const Item = styled(Box)`
    display: flex;
    padding: 1rem;
    flex-direction: column;
    border-bottom: 1px dashed ${props => props.theme.palette.divider};

    &:last-child {
        border: 0;
    }
`