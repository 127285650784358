import { Box, Card, CircularProgress, Drawer, IconButton, Tab, Tabs, Typography } from "@mui/material";
import { CardContagem, Container, HeaderContainer } from "./styles";
import { FiltrosListagemPedidos } from "./containers/FiltrosListagemPedidos";
import { useFetchPedidos } from "../../hooks/useFetchPedidos";
import { useEffect, useRef, useState } from "react";
import { IPedido } from "../../interface/IPedido";
import { usePedidos } from "../../hooks/usePedidos";
import { useSearchParams } from "react-router-dom";
import { StatusPedido, StatusPedidoDescricao } from "../../enum/StatusPedido";
import { useMarketplaces } from "../../../marketplace/hooks/useMarketplaces";
import { useFetchMarketplaces } from "../../../marketplace/hooks/useFetchMarketplaces";
import { TabelaPedidos } from "./containers/TabelaPedidos";
import { ChevronLeft, Close, ReceiptLong } from "@mui/icons-material";
import { PedidoDetalhado } from "../../containers/PedidoDetalhado";
import { useTamanhoTela } from "../../../core/hooks/useTamanhoTela";
import { FerramentasPedidos } from "./containers/FerramentasPedidos";

type TabListagemPedidos = 'Em aberto' | 'Concluído' | 'Cancelado';

export const ListagemPedidos = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [pedidos, setPedidos] = useState<IPedido[]>([]);
    const [loading, setLoading] = useState(false);
    const [pedidosSelecionados, setPedidosSelecionados] = useState<IPedido[]>([]);
    const [uuidPedidoDetalhes, setUuidPedidoDetalhes] = useState<string | undefined>(undefined);
    const [tab, setTab] = useState<TabListagemPedidos>('Em aberto');
    const { listarPedidos } = usePedidos();
    const { marketplaces, loading: loadingMarketplaces } = useFetchMarketplaces();
    const ultimaInteracao = useRef<Date | null>(null);

    const handleListarPedidos = async (mostrarLoading = true) => {
        if (!searchParams.get('dataInicio') || !searchParams.get('dataFim')) return;

        if (mostrarLoading) setLoading(true);

        let status: StatusPedidoDescricao[] = [];
        if (tab === "Em aberto") {
            status = [
                StatusPedidoDescricao["Em preparo"],
                StatusPedidoDescricao["Em andamento"],
                StatusPedidoDescricao["Em transporte"],
                StatusPedidoDescricao["Aguardando confirmação"],
                StatusPedidoDescricao["Pronto para retirada"],
                StatusPedidoDescricao["Em negociação"],
            ];
        } else if (tab === "Concluído") {
            status = [StatusPedidoDescricao["Concluído"]];
        } else if (tab === "Cancelado") {
            status = [StatusPedidoDescricao["Cancelado"]];
        }

        const pedidosListados = await listarPedidos({
            dataInicio: searchParams.get('dataInicio'),
            dataFim: searchParams.get('dataFim'),
            numeroPedido: searchParams.get('numeroPedido'),
            tipos: searchParams.get('tipos'),
            status: status.join(','),
        });

        if (pedidosListados) {
            setPedidos(pedidosListados);
        }

        setPedidosSelecionados([]);
        if (mostrarLoading) setLoading(false);
    };

    const selecionarPedido = (pedido: IPedido) => {
        const pedidoJaSelecionadoIndex = pedidosSelecionados?.findIndex(pedidoSelecionado => pedidoSelecionado.uuid === pedido.uuid);
    
        if (pedidoJaSelecionadoIndex === -1) {
            setPedidosSelecionados(pedidosSelecionados => [...pedidosSelecionados, pedido]);
        } else {
            setPedidosSelecionados(pedidosSelecionados => pedidosSelecionados.filter(p => p.uuid !== pedido.uuid));
        }

        ultimaInteracao.current = new Date();
    };
    

    const handleTab = (event: React.SyntheticEvent, tab: TabListagemPedidos) => {
        setTab(tab);
    };

    useEffect(() => {
        const intervalo = setInterval(() => {
            const agora = new Date();
            const ultimaInteracaoTempo = ultimaInteracao.current
                ? (agora.getTime() - ultimaInteracao.current.getTime()) / 1000
                : Infinity;

            if (ultimaInteracaoTempo > 120) {
                setPedidosSelecionados([]);
                ultimaInteracao.current = null;
            }

            if (ultimaInteracaoTempo >= 0) {
                handleListarPedidos(false);
            }
        }, 30000);

        return () => clearInterval(intervalo);
    }, [searchParams, tab]);

    useEffect(() => {
        handleListarPedidos(true);
    }, [searchParams, tab]);

    function tabProps(uuid: number) {
        return {
            id: `simple-tab-${uuid}`,
            'aria-controls': `simple-tabpanel-${uuid}`,
        };
    }

    const abrirDetalhes = (uuid: string) => setUuidPedidoDetalhes(uuid);
    const fecharDetalhes = () => setUuidPedidoDetalhes(undefined);
    const { modeloTela } = useTamanhoTela();
    return (
        <Container>
            {uuidPedidoDetalhes && (
                <Drawer
                    anchor="right"
                    open={true}
                    onClose={fecharDetalhes}
                    sx={{ zIndex: 9999 }}
                >
                    <Box sx={{ 
                        width: modeloTela == "desktop" ? '60vw' : '100vw', 
                        padding: '1rem',
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-end" 
                    }}>
                        <IconButton onClick={fecharDetalhes}>
                            <Close />
                        </IconButton>
                        <PedidoDetalhado uuid={uuidPedidoDetalhes} />
                    </Box>
                </Drawer>
            )}
            <HeaderContainer>
                <Typography variant={"h5"}>
                    Pedidos
                </Typography>
                <Card variant="outlined">
                    <CardContagem>
                        <Box sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "0.5rem"
                        }}>
                            <Typography fontSize={12} color={"GrayText"}>
                                {tab}
                            </Typography>
                            {loading
                                ? <CircularProgress color="primary" size={10} />
                                : <Typography>{pedidos?.length}</Typography>
                            }
                        </Box>
                        <ReceiptLong color="primary" sx={{ fontSize: 30 }} />
                    </CardContagem>
                </Card>
            </HeaderContainer>
            <Tabs onChange={handleTab} value={tab} sx={{ mt: "1rem", borderBottom: 1, borderColor: 'divider', width: "100%" }}>
                <Tab 
                    label="Em aberto" 
                    value={'Em aberto'} 
                    {...tabProps(StatusPedido["Em preparo"])} 
                />
                <Tab 
                    label="Concluídos" 
                    value={'Concluído'} 
                    {...tabProps(StatusPedido["Concluído"])} 
                />
                <Tab 
                    label="Cancelados" 
                    value={'Cancelado'} 
                    {...tabProps(StatusPedido["Cancelado"])} 
                />
            </Tabs>
            <FiltrosListagemPedidos />
            <TabelaPedidos
                pedidos={pedidos}
                loading={loading}
                loadingMarketplaces={loadingMarketplaces}
                marketplaces={marketplaces}
                abrirDetalhes={abrirDetalhes}
                pedidosSelecionados={pedidosSelecionados}
                selecionarPedido={selecionarPedido}
            />
            <FerramentasPedidos 
                pedidosSelecionados={pedidosSelecionados}
                atualizarListagem={handleListarPedidos}
                desselecionarPedido={selecionarPedido}
            />
        </Container>
    );
}