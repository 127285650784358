import { Divider, FormControl, InputAdornment, InputLabel, OutlinedInput, RadioGroup, TextField, Typography } from "@mui/material";
import { RadioCard } from "../../../../../../../../../core/components/RadioCard";
import { INegociacaoPedido } from "../../../../../../../../interface/INegociacaoPedido";
import { IAlternativaNegociacaoPedido } from "../../../../../../../../interface/IAlternativaNegociacaoPedido";
import { useMoeda } from "../../../../../../../../../core/hooks/useMoeda";
import { useState } from "react";
import { OutroValorContainer } from "./styles";
import { IPedido } from "../../../../../../../../interface/IPedido";

interface AlternativasNegociacaoPedidoProps {
    negociacao: INegociacaoPedido;
    alternativa: IAlternativaNegociacaoPedido;
    pedido: IPedido;
    opcao?: string;
    handleOpcao: (opcao: string) => void;
    motivo?: string;
    handleMotivo: (motivo: string) => void;
    valor?: string;
    handleValor: (valor: string) => void;
}

export const AlternativasNegociacaoPedidoReembolsoTotal = ({ opcao, handleOpcao, alternativa, pedido, handleMotivo, motivo, valor, handleValor }: AlternativasNegociacaoPedidoProps) => {
    const { real } = useMoeda();
    const total = pedido?.valorTotal;

    return (
        <RadioGroup
            aria-labelledby={`alternativas-negociacao-${alternativa.uuid}`}
            name={`alternativa-${alternativa.uuid}`}
            sx={{ gap: "1rem" }}
            onChange={(e, v) => handleOpcao(v)}
        >
            <RadioCard
                labelTitle={<Typography variant="subtitle1" fontWeight={"bold"}>Aceitar reembolso de {real(total)}</Typography>}
                labelDescription={<Typography variant="subtitle1">Cliente receberá o valor do(s) item(ns)</Typography>}
                fullWidth
                labelValue={"ACEITAR"}
                selected={opcao == "ACEITAR"}
                onClick={() => handleOpcao("ACEITAR")}
            />
            <RadioCard
                labelTitle={<Typography variant="subtitle1" fontWeight={"bold"}>Envie outro valor</Typography>}
                labelDescription={<Typography variant="subtitle1">Cliente pode aceitar ou recusar o valor</Typography>}
                fullWidth
                labelValue={"OUTRO_VALOR"}
                selected={opcao == "OUTRO_VALOR"}
                onClick={() => handleOpcao("OUTRO_VALOR")}
                toolsPosition="BOTTOM_LEFT"
                tools={opcao == "OUTRO_VALOR" ? (
                    <OutroValorContainer>
                        <Divider />
                        <Typography>
                            Qual o valor que você gostaria de reembolsar o cliente?
                        </Typography>
                        <FormControl fullWidth>
                        <OutlinedInput
                            sx={{background: "white"}}
                            id="preco-item-complemento"
                            startAdornment={<InputAdornment position="start">R$</InputAdornment>}
                            value={valor}
                            onChange={(e) => handleValor(e.target.value)}
                        />
                        </FormControl>
                        <Typography>
                            Você pode oferecer um valor menor ou até <b>{real(alternativa.metadados?.valorMaximo)}</b>
                        </Typography>
                    </OutroValorContainer>
                ) : undefined}
            />
            <RadioCard
                labelTitle={<Typography variant="subtitle1" fontWeight={"bold"}>Recusar reembolso de {real(total)}</Typography>}
                labelDescription={<Typography variant="subtitle1">Cliente ainda pode solicitar uma análise do iFood</Typography>}
                fullWidth
                labelValue={"RECUSAR"}
                selected={opcao == "RECUSAR"}
                onClick={() => handleOpcao("RECUSAR")}
                toolsPosition="BOTTOM_LEFT"
                tools={opcao == "RECUSAR" ? (
                    <OutroValorContainer>
                        <Divider />
                        <Typography>
                            Conte para o cliente por qual motivo você vai recusar:
                        </Typography>
                        <FormControl fullWidth>
                        <OutlinedInput
                            sx={{background: "white"}}
                            id="motivo-recusa"
                            multiline
                            minRows={2}
                            value={motivo}
                            onChange={(e) => handleMotivo(e.target.value)}
                        />
                        </FormControl>
                    </OutroValorContainer>
                ) : undefined}
            />
        </RadioGroup>
    );
}