import styled from "@emotion/styled";
import { Box, Paper, Stepper } from "@mui/material";

export const PageContainer = styled(Box)`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
`;

export const ContentContainer = styled(Box)`
    width: 100%;
    display: flex;
    margin-top: 2rem;
    justify-content: space-between;
    @media(max-width: 1080px) {
        flex-direction: column;
    }
`;