import { AccordionDetails, Alert, AlertTitle, Box, Button, CircularProgress, Divider, Typography } from "@mui/material";

interface PrimeiraEtapaVinculoIFoodProps {
    loading: boolean;
    vincular: () => void;
}

export const PrimeiraEtapaVinculoIFood = ({ vincular, loading }: PrimeiraEtapaVinculoIFoodProps) => {
    return (
        <Box>
            <Typography variant="h6">Integração iFood</Typography>
            <Box sx={{ mt: "1rem", mb: "1rem"}}>
                <Typography fontWeight={"bold"}>1° Redirecionamento</Typography>
                <Typography>Após o clique uma nova janela será aberta direcionada para sua conta parceiro IFood.</Typography>
            </Box>
            <Divider />
            <Box sx={{ mt: "1rem", mb: "1rem"}}>
                <Typography fontWeight={"bold"}>2° Conceda autorização ao aplicativo Space Food</Typography>
                <Typography>Basta clicar em autorizar na tela que será aberta..</Typography>
            </Box>
            <Divider />
            <Box sx={{ mt: "1rem", mb: "1rem"}}>
            <Typography fontWeight={"bold"}>3° Copie o código iFood e envie para nós</Typography>
            <Typography>Ao autorizar a utilização do aplicativo um código será gerado na sua conta Parceiro iFood, copie e cole aqui a baixo.</Typography>
            </Box>
            <Button onClick={() => vincular()} style={{ marginTop: "1rem" }} size="large" variant="contained" color="primary" fullWidth>
                {loading ? <CircularProgress color="inherit" /> : "Iniciar integração"}
            </Button>
        </Box>
    );
};