import { useEffect, useState } from "react";
import { IMarketplace } from "../../../interfaces/IMarketplace";
import { useSnackbar } from "../../../../core/contexts/SnackbarContext";
import { useMarketplaces } from "../../../hooks/useMarketplaces";
import { useIFood } from "../../../hooks/useIFood";
import { IIntegracaoIFood } from "../../../interfaces/IIntegracaoMarketplace";
import { PageContainer } from "./styles";
import { PrimeiraEtapaVinculoIFood } from "./components/PrimeiraEtapaVinculoIFood";
import { SegundaEtapaVinculoIFood } from "./components/SegundaEtapaVinculoIFood";
import { TerceiraEtapaVinculoIFood } from "./components/TerceiraEtapaVinculoIFood";
import { QuartaEtapaVinculoIFood } from "./components/QuartaEtapaVinculoIFood";

interface VincularMarketplaceProps {
    marketplace: IMarketplace
    handleRefresh: () => void,
    loadingFetch: boolean
}
export const VincularMerchantIFoodV2 = ({
    handleRefresh,
    loadingFetch,
    marketplace
}: VincularMarketplaceProps) => {
    const [etapa, setEtapa] = useState<number>(1);
    const [authorizationCode, setAuthorizationCode] = useState<string>("");

    const [dadosVinculo, setDadosVinculo] = useState<{
        accessToken: string | undefined;
        merchantId: string | undefined;
        catalogId: string | undefined;
        refreshToken: string | undefined;
        authorizationCodeVerifier: string | undefined;
    }>({
        accessToken: undefined,
        merchantId: undefined,
        catalogId: undefined,
        refreshToken: undefined,
        authorizationCodeVerifier: undefined
    });
    const [estabelecimentos, setEstabelecimentos] = useState<{
        id: string;
        nome: string;
    }[]>([]);
    const [catalogos, setCatalogos] = useState<{
        id: string;
        contexto: string;
    }[]>([]);
    const [authorizationCodeVerifier, setAuthorizationCodeVerifier] = useState<string>("");

    const snackbar = useSnackbar();
    const {
        salvar
    } = useMarketplaces();

    const {
        listarEstabelecimentos,
        listarCatalogos,
        loading,
        vincular,
    } = useIFood();

    const integracao = marketplace?.configuracao?.integracao as IIntegracaoIFood;

    const listarEstabelecimentosMarketplace = async () => {
        const estabelecimentosMarketplace = await listarEstabelecimentos(marketplace.uuid as string);
        if (estabelecimentosMarketplace) {
            setEstabelecimentos(estabelecimentosMarketplace);
        }
    };

    const listarCatalogosMarketplace = async () => {
        const catalogosMarketplace = await listarCatalogos(marketplace.uuid as string);
        if (catalogosMarketplace) {
            setCatalogos(catalogosMarketplace);
        }
    };

    const excluir = async () => {
        await salvar({
            ...marketplace,
            configuracao: {
                ...marketplace.configuracao,
                integracao: undefined
            }
        });
        window.location.reload();
    }

    const handleVincularEstabelecimento = async (merchantId: string) => {
        await salvar({
            ...marketplace,
            configuracao: {
                ...marketplace.configuracao,
                integracao: {
                    accessToken: dadosVinculo.accessToken ?? integracao?.accessToken,
                    refreshToken: dadosVinculo?.refreshToken ?? integracao.refreshToken,
                    merchantId
                }
            }
        });
        setDadosVinculo({
            ...dadosVinculo,
            merchantId
        });
        await listarCatalogosMarketplace();
        setEtapa(4);
    }

    const handleVincularMarketplace = async (authorizationCode?: string) => {
        if (dadosVinculo?.accessToken && marketplace.uuid) {
            await listarEstabelecimentosMarketplace();
        }

        const resposta = await vincular(
            marketplace.uuid as string,
            authorizationCode,
            authorizationCodeVerifier
        );

        if (resposta?.authorizationCodeVerifier) {
            setAuthorizationCodeVerifier(resposta?.authorizationCodeVerifier)
            setEtapa(2);
            window.open(resposta.verificationUrlComplete);
            return;
        }

        setDadosVinculo({
            accessToken: resposta?.accessToken || dadosVinculo?.accessToken,
            merchantId: dadosVinculo.merchantId,
            catalogId: dadosVinculo.catalogId,
            refreshToken: resposta?.refreshToken || dadosVinculo.refreshToken,
            authorizationCodeVerifier: authorizationCodeVerifier ?? undefined
        });
        setEtapa(3);
        handleRefresh();
    };

    const voltar2etapa = async () => {
        await excluir();
    }

    const voltar3etapa = async () => {
        await excluir();
    }

    const voltar4etapa = async () => {
        await salvar({
            ...marketplace,
            configuracao: {
                ...marketplace.configuracao,
                integracao: {
                    ...dadosVinculo,
                    merchantId: undefined
                }
            }
        });
        window.location.reload();
    }

    const handleVincularCatalogo = async (catalogId?: string) => {
        const catalogo = catalogos?.find(catologo => catologo.id == catalogId);
        if(!catalogo) {
            return snackbar("Catálogo não encontrado", { severity: "error" });
        }
        salvar({
            ...marketplace,
            configuracao: {
                ...marketplace.configuracao,
                integracao: {
                    accessToken: dadosVinculo?.accessToken,
                    merchantId: dadosVinculo?.merchantId,
                    refreshToken: dadosVinculo?.refreshToken,
                    catalogId
                }
            }
        });
        setDadosVinculo({
            ...dadosVinculo,
            catalogId
        })
        window.location.reload();
    };

    useEffect(() => {
        if (marketplace) {
            setDadosVinculo({
                accessToken: integracao?.accessToken,
                merchantId: integracao?.merchantId,
                refreshToken: integracao?.refreshToken,
                catalogId: integracao?.catalogId,
                authorizationCodeVerifier: authorizationCodeVerifier ?? undefined
            });
        }
    }, [marketplace])

    useEffect(() => {
        if (dadosVinculo.accessToken && !dadosVinculo.merchantId) {
            setEtapa(3);
            listarEstabelecimentosMarketplace();
            return;
        }

        if (dadosVinculo?.merchantId) {
            setEtapa(4);
            listarCatalogosMarketplace();
            return;
        }
    }, [dadosVinculo]);

    return (
        <PageContainer>
            {etapa == 1 && <PrimeiraEtapaVinculoIFood
                vincular={handleVincularMarketplace}
                loading={loading}
            />
            }
            {etapa == 2 && <SegundaEtapaVinculoIFood
                vincular={handleVincularMarketplace}
                loading={loading}
                authorizationCode={authorizationCode}
                setAuthorizationCode={setAuthorizationCode}
                voltar={voltar2etapa}
            />
            }
            {etapa == 3 && <TerceiraEtapaVinculoIFood
                estabelecimentoSelecionado={dadosVinculo?.merchantId}
                estabelecimentos={estabelecimentos}
                loading={loading}
                vincular={handleVincularEstabelecimento}
                voltar={voltar3etapa}
            />
            }
            {etapa == 4 && <QuartaEtapaVinculoIFood
                loading={loading}
                catalogos={catalogos}
                catalogoSelecionado={dadosVinculo?.catalogId}
                vincular={handleVincularCatalogo}
                voltar={voltar4etapa}
            />}
        </PageContainer>
    );
}