import styled from "@emotion/styled";
import { Box, Paper } from "@mui/material";

export const ToolsPaper = styled(Paper)`
    width: 100vw;
    padding: 1rem 2rem;
    position: fixed;
    bottom: 0%;
    right: 0%;
    display: flex;
    justify-content: flex-end;
`

export const Tools = styled(Box)`
    width: calc(100% - 240px);
    display: flex;
    justify-content: space-between;
`

export const ToolsTitle = styled(Box)`
    display: flex;
    align-items: center;
`

export const ToolsContainer = styled(Box)`
    display: flex;
    align-items: center;
    gap: 1rem;
`