export enum TipoNegociacaoPedido {
    'APOS_DELIVERY' = 'APOS_DELIVERY',
    'ATRASO' = 'ATRASO',
    'TEMPO_PREPARACAO' = 'TEMPO_PREPARACAO',
    'APOS_DELIVERY_PARCIAL' = 'APOS_DELIVERY_PARCIAL',
}

export enum TipoNegociacaoPedidoDescricao {
    'APOS_DELIVERY' = 'Reembolso',
    'ATRASO' = 'Atraso',
    'TEMPO_PREPARACAO' = 'Tempo de preparação',
    'APOS_DELIVERY_PARCIAL' = 'Reembolso (Parcial)',
}