import { useMemo, useState } from "react";
import { useSnackbar } from "../../core/contexts/SnackbarContext";
import { ApiFactory } from "../../core/config/ApiFactory";
import { AxiosError } from "axios";
import { IMarketplace } from "../interfaces/IMarketplace";
import { IMotivoCancelamentoIFood } from "../interfaces/IMotivoCancelamentoIFood";

export const useIFood = () => {
    const [loading, setLoading] = useState(false);
    const showSnackbar = useSnackbar();
    const apiMarketplaces = useMemo(() => ApiFactory.getApi("MS_MARKETPLACES"), []);


    const listarCatalogos = async (marketplaceUuid: string): Promise<null | {
        id: string;
        contexto: string;
    }[]> => {
        if(loading) {
            return null;
        }
        setLoading(true);

        try { 
            const { data } = await apiMarketplaces.get(`/ifood/${marketplaceUuid}/catalogos`);
            if(!data) {
                showSnackbar("Ocorreu um erro ao vincular o marketplace", { severity: "error" });
                return null;
            }

            return data;
        } catch (error) {
            if(error instanceof AxiosError) {
                showSnackbar(error.response?.data.message, { severity: 'error' })
                return null;
            }

            showSnackbar("Ocorreu um erro ao vincular o marketplace", { severity: "error" });
            return null;
        } finally {
            setLoading(false);
        }
    }

    const listarEstabelecimentos = async (marketplaceUuid: string): Promise<null | {
        id: string;
        nome: string;
    }[]> => {
        if(loading) {
            return null;
        }
        setLoading(true);

        try { 
            const { data } = await apiMarketplaces.get(`/ifood/${marketplaceUuid}/estabelecimentos`);
            if(!data) {
                showSnackbar("Ocorreu um erro ao vincular o marketplace", { severity: "error" });
                return null;
            }

            return data;
        } catch (error) {
            if(error instanceof AxiosError) {
                showSnackbar(error.response?.data.message, { severity: 'error' })
                return null;
            }

            showSnackbar("Ocorreu um erro ao vincular o marketplace", { severity: "error" });
            return null;
        } finally {
            setLoading(false);
        }
    }

    const vincular = async (
        marketplaceUuid: string,
        authorizationCode?: string,
        authorizationCodeVerifier?: string
    ): Promise<null | {
        userCode?: string;
        authorizationCodeVerifier?: string;
        verificationUrlComplete?: string;
        accessToken?: string;
        refreshToken?: string;
    }> => {
        if(loading) {
            return null;
        }
        setLoading(true);

        try { 
            const { data } = await apiMarketplaces.post(`/ifood/${marketplaceUuid}/vincular`, {
                authorizationCode,
                authorizationCodeVerifier
            });

            if(!data) {
                showSnackbar("Ocorreu um erro ao vincular o marketplace", { severity: "error" });
                return null;
            }

            return data;
        } catch (error) {
            if(error instanceof AxiosError) {
                showSnackbar(error.response?.data.message, { severity: 'error' })
                return null;
            }

            showSnackbar("Ocorreu um erro ao vincular o marketplace", { severity: "error" });
            return null;
        } finally {
            setLoading(false);
        }
    }

    const buscarMotivosCancelamento = async (marketplaceUuid: string, pedidoUuid: string): Promise<IMotivoCancelamentoIFood[] | null> => {
        if(loading) {
            return null;
        }
        setLoading(true);

        try { 
            const { data } = await apiMarketplaces.get(`/ifood/${marketplaceUuid}/pedidos/${pedidoUuid}/motivos-cancelamento`);

            if(!data) {
                showSnackbar("Ocorreu um erro ao listar os motivos de cancelamento", { severity: "error" });
                return null;
            }

            return data;
        } catch (error) {
            if(error instanceof AxiosError) {
                showSnackbar(error.response?.data.message, { severity: 'error' })
                return null;
            }

            showSnackbar("Ocorreu um erro ao vincular o marketplace", { severity: "error" });
            return null;
        } finally {
            setLoading(false);
        }
    }

    return { listarEstabelecimentos, listarCatalogos, vincular, loading, buscarMotivosCancelamento }
}