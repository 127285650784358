import React from 'react';
import { StatusPedido, StatusPedidoDescricao, StatusPedidosCores } from '../../enum/StatusPedido';
import styled from '@emotion/styled';
import { Box } from '@mui/material';

interface StatusPillProps {
  status: StatusPedido;
}

const getStatusText = (status: StatusPedido): keyof typeof StatusPedidoDescricao => {
    return StatusPedido[status] as keyof typeof StatusPedidoDescricao;
  };

export const StatusPedidoPilula: React.FC<StatusPillProps> = ({ status }) => {
  const statusText = getStatusText(status);
  const statusColor = StatusPedidosCores[statusText];

  return (
    <Pill style={{ 
        color: statusColor,
        border: `1px solid ${statusColor}`
    }}>
      {statusText}
    </Pill>
  );
};

const Pill = styled(Box)`
  padding: 4px 14px;
  border-radius: 20px;
  font-size: 14px;
  display: inline-block;
  text-align: center;
`;

