import { AccordionDetails, Alert, AlertTitle, Box, Button, CircularProgress, FormControl, InputLabel, MenuItem, Select, Typography } from "@mui/material";
import { IMarketplace } from "../../../../../interfaces/IMarketplace";
import { ICatalogoIFood } from "../../../../../interfaces/ICatalogoIFood";
import { useEffect, useState } from "react";

interface QuartaEtapaVinculoIFoodProps {
    vincular: (catalogId: string) => void;
    catalogoSelecionado: string | undefined;
    voltar: () => void;
    catalogos: ICatalogoIFood[]
    loading: boolean;
}

export const QuartaEtapaVinculoIFood = ({
    vincular,
    voltar,
    catalogos,
    catalogoSelecionado,
    loading
}: QuartaEtapaVinculoIFoodProps) => {
    const [catalogId, setCatalogId] = useState('');
    
    useEffect(() => {
        if(catalogoSelecionado) {
            setCatalogId(catalogoSelecionado);
        }
    }, [catalogoSelecionado])

    return (
        <Box>
            <Typography variant="h6">Estabelecimento</Typography>
            <Typography variant="subtitle2" fontWeight={400} color={"GrayText"} sx={{ mt: "1rem" }}>
                Selecione o catálogo do iFood que você deseja integrar.
            </Typography>
            <FormControl style={{ width: '100%', margin: "0.7rem 0" }}>
                <InputLabel>Catálogos</InputLabel>
                <Select
                    labelId="catalogos-label"
                    id="catalogos"
                    label="Catálogos"
                    value={catalogId}
                    onChange={(e) => setCatalogId(e.target.value)}
                >
                    {catalogos && catalogos?.map(catalogo => {
                        return (
                            <MenuItem key={catalogo?.id} value={catalogo?.id}>
                                {catalogo?.contexto}
                            </MenuItem>
                        );
                    })}
                </Select>
            </FormControl>
            <Box sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
                marginTop: '1rem'
            }}>
                <Button onClick={voltar} variant="outlined" color="primary" sx={{ mr: "0.5rem" }}>
                    Voltar
                </Button>
                <Button onClick={() => vincular(catalogId)} variant="contained" color="primary">
                    {loading ? <CircularProgress size={20} color="inherit" /> : "Vincular"}
                </Button>
            </Box>
        </Box>
    );
};