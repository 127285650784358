export enum TipoCupom {
    "ITEM" = "ITEM",
    "ITEM_DESCONTO_PROGRESSIVO" = "ITEM_DESCONTO_PROGRESSIVO",
    "TAXA_ENTREGA" = "TAXA_ENTREGA",
    "TOTAL" = "TOTAL"
}

export enum TipoCupomDescricao {
    "ITEM" = "Item",
    "ITEM_DESCONTO_PROGRESSIVO" = "Item progressivo",
    "TAXA_ENTREGA" = "Taxa de entrega",
    "TOTAL" = "Total"
}