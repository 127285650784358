export const Environment = {
    MS_USUARIOS_ESTABELECIMENTO:'https://spacefood.com.br/api/v1/usuarios/estabelecimento',
    MS_USUARIOS_RECUPERAR_SENHA:'https://spacefood.com.br/api/v1/usuarios/estabelecimento',
    MS_ESTABELECIMENTOS:'https://spacefood.com.br/api/v1/estabelecimento',
    MS_FINANCEIRO:'https://spacefood.com.br/api/v1/financeiro',
    MS_CLIENTES: 'https://spacefood.com.br/api/v1/estabelecimento/clientes',
    MS_PRODUTOS: "https://spacefood.com.br/api/v1/produtos",
    MS_PEDIDOS: "https://spacefood.com.br/api/v1/pedidos",
    MS_MARKETPLACES: "https://spacefood.com.br/api/v1/marketplace",
    TOKEN_KEY:"@token",
    TOKEN_ESTABELECIMENTO_KEY:"@defaultToken",
    TOKEN_RECUPERAR_SENHA_KEY: "@recoverPasswordToken"
}