import { AccordionDetails, Alert, AlertTitle, Box, Button, CircularProgress, FormControl, InputLabel, MenuItem, Select, Typography } from "@mui/material";
import { IMarketplace } from "../../../../../interfaces/IMarketplace";
import { IEstabelecimentoIFood } from "../../../../../interfaces/IEstabelecimentoIFood";
import { useMarketplaces } from "../../../../../hooks/useMarketplaces";
import { useEffect, useState } from "react";

interface TerceiraEtapaVinculoIFoodProps {
    estabelecimentoSelecionado: string | undefined;
    estabelecimentos: IEstabelecimentoIFood[];
    voltar: () => void;
    vincular: (merchantId: string) => void;
    loading: boolean;
}

export const TerceiraEtapaVinculoIFood = ({
    estabelecimentoSelecionado,
    estabelecimentos,
    voltar,
    vincular,
    loading
}: TerceiraEtapaVinculoIFoodProps) => {
    const [merchantId, setMerchantId] = useState('');
    
    useEffect(() => {
        if(estabelecimentoSelecionado) {
            setMerchantId(estabelecimentoSelecionado);
        }
    }, [estabelecimentoSelecionado])

    return (
        <>
            <Box>
                <Typography variant="h6">Estabelecimento</Typography>
                <Typography variant="subtitle2" fontWeight={400} color={"GrayText"} sx={{ mt: "1rem" }}>
                    Selecione o estabelecimento do iFood que você deseja integrar.
                </Typography>
                <FormControl style={{ width: '100%', margin: "0.7rem 0" }}>
                    <InputLabel>Estabelecimento</InputLabel>
                    <Select
                        labelId="estabelecimentos-label"
                        id="estabelecimentos"
                        label="Estabelecimento"
                        value={merchantId}
                        onChange={(e) => {
                            setMerchantId(e.target.value)
                        }}
                    >
                        {estabelecimentos && estabelecimentos?.map(estabelecimento => {
                            return (
                                <MenuItem key={estabelecimento?.id} value={estabelecimento?.id}>
                                    {estabelecimento?.nome}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </FormControl>
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                    marginTop: '1rem'
                }}>
                    <Button onClick={voltar} variant="outlined" color="primary" sx={{ mr: "0.5rem" }}>
                        Voltar
                    </Button>
                    <Button onClick={() => vincular(merchantId)} variant="contained" color="primary">
                        {loading ? <CircularProgress size={20} color="inherit" /> : "Vincular"}
                    </Button>
                </Box>
            </Box>
        </>
    );
};