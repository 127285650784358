import styled from "@emotion/styled";
import { Box } from "@mui/system";

export const LoadingContainer = styled(Box)`
    width: 100%;
    min-height: 350px;
    display: flex;
    align-items: center;
    justify-content: center;
`

export const NenhumPedidoContainer = styled(Box)`
    width: 100%;
    min-height: 350px;
    display: flex;
    align-items: center;
    justify-content: center;
`