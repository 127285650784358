import { Alert, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, Typography } from "@mui/material"
import { ReactNode, useState } from "react";

interface ConfirmarAcaoTextoModalProps {
    open: boolean;
    titulo: string;
    mensagem: string | ReactNode;
    parametro: string;
    cancelar: () => void;
    confirmar: () => any
    loading?: boolean;
}

export const ConfirmarAcaoTextoModal = ({ cancelar, confirmar, mensagem, open, titulo, parametro, loading }: ConfirmarAcaoTextoModalProps) => {
    const [confirmacao, setConfirmacao] = useState('');

    const handleClose = () => {
        setConfirmacao('');
        cancelar();
    }

    const handleConfirmar = () => {
        setConfirmacao('');
        confirmar();
    }

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>
                {titulo}
            </DialogTitle>
            <DialogContent>
                <Alert severity="warning">
                    A ação não pode ser revertida
                </Alert>
                <Typography sx={{ marginTop: "1rem" }}>
                    {mensagem}
                </Typography>
                <TextField
                    value={confirmacao}
                    onChange={(e) => setConfirmacao(e.target.value)}
                    sx={{ width: "100%", marginTop: "0.5rem" }}
                    placeholder={parametro}
                    size="small"
                />
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={handleClose}>Cancelar</Button>
                {loading ? (
                    <Button>
                        <CircularProgress size={20} />
                    </Button>
                ) : (
                    <Button
                        onClick={() => handleConfirmar()}
                        type="button"
                        variant="contained"
                        disabled={parametro.trim() != confirmacao.trim()}
                    >
                        Excluir
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    )
}