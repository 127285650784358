import { Box, Button, Card, CircularProgress, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { AlternativasNegociacaoCard, Item, ItensContainerCard, MensagemNegociacaoCard, NegociacaoCard, NegociacaoCardHeader } from "../../styles";
import { TipoNegociacaoPedidoDescricao } from "../../../../../../../../enum/TipoNegociacaoPedido";
import { TemposNegociacao } from "../TemposNegociacao";
import { Download, Message, OpenInNew } from "@mui/icons-material";
import { AlternativasNegociacaoPedidoReembolsoTotal } from "../AlternativasNegociacaoPedidoReembolsoTotal";
import { INegociacaoPedido } from "../../../../../../../../interface/INegociacaoPedido";
import { IPedido } from "../../../../../../../../interface/IPedido";
import { useMoeda } from "../../../../../../../../../core/hooks/useMoeda";
import { useDownload } from "../../../../../../../../../core/hooks/useDownload";

interface NegociacaoEmPreparacaoProps {
    negociacao: INegociacaoPedido;
    pedido: IPedido;
    aceitarNegociacao: (pedidoUuid: string) => Promise<void>;
    recusarNegociacao: (pedidoUuid: string, motivo: string) => Promise<void>;
    loading: boolean;
}

export const NegociacaoEmPreparacao = ({ negociacao, aceitarNegociacao, recusarNegociacao, pedido, loading }: NegociacaoEmPreparacaoProps) => {
    return (
        <NegociacaoCard variant="outlined">
            <NegociacaoCardHeader>
                <Typography variant="h6">
                    {TipoNegociacaoPedidoDescricao[negociacao.tipo]}
                </Typography>
            </NegociacaoCardHeader>
            {negociacao && (
                <TemposNegociacao
                    criadoAs={negociacao.criadoAs}
                    expiradoAs={negociacao.expiradoAs}
                />
            )}
            {negociacao.mensagem && (
                <MensagemNegociacaoCard variant="outlined">
                    <Typography fontWeight={"bold"}>
                        Mensagem do consumidor
                    </Typography>
                    <Typography variant="subtitle2">
                        {negociacao.mensagem}
                    </Typography>
                </MensagemNegociacaoCard>
            )}
            <Button onClick={() => aceitarNegociacao(pedido.uuid)} variant={loading ? "outlined" : "contained"} fullWidth size="large" disabled={loading}>
                {loading ? <CircularProgress color="primary" size={25} /> : "Aceitar cancelamento"}
            </Button>
            <Button onClick={() => recusarNegociacao(pedido.uuid, "Não será possível efetuar o cancelamento do pedido")} variant="outlined" fullWidth size="large">
                Recusar cancelamento
            </Button>
        </NegociacaoCard>
    );
}